import { render, staticRenderFns } from "./Press.vue?vue&type=template&id=615fe5ea&scoped=true&"
import script from "./Press.vue?vue&type=script&lang=js&"
export * from "./Press.vue?vue&type=script&lang=js&"
import style0 from "./Press.vue?vue&type=style&index=0&id=615fe5ea&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "615fe5ea",
  null
  
)

export default component.exports