<template>
  <div>
    <div class="home">
      <div class="justify-content-between nav">
        <b-nav class="justify-content-start" style="width:33%;">
          <b-nav-item @click="press">Press</b-nav-item>
          <b-nav-item @click="exhibits">Exhibits</b-nav-item>
        </b-nav>
        <div style="width:33%;">
          <img alt="Vue logo" src="../assets/logo.png">
          <div class="title mt-5"><h3>Works</h3></div>
        </div>
        <b-nav class="justify-content-end" style="width:33%;">
          <b-nav-item @click="about">About</b-nav-item>
          <b-nav-item class="active">Works</b-nav-item>
          <b-nav-item @click="contact">Contact</b-nav-item>
        </b-nav>
      </div>
      <div class="works-wrapper mt-5">
        <div class="text-center">
          <h1 class="h1">Exposing one’s self through art is very liberating—it gives permission to
          vulnerability. Art comforts the senses and purges them from unwanted
          tensions. I like to think my work depicts controlled chaos; most are colorful
          and vibrant yet give an overall cohesive effect.
          </h1>
        </div>
        <div class="d-flex flex-wrap justify-content-around mt-5">
          <img @click="goTo(work)" v-for="work in works" :key="work.id" 
          :src="require('../assets/Works/' + work + '.png')" alt="" class="work">
        </div>
      </div>
    </div>
    <div class="footer mt-5">
      <h3 class="text-white">&copy; 2022 SALPONCEENRILE.COM</h3>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
    
  },
  data() {
    return {
      works: [
        "artworks2024",
       "recentArtworks",
        "awakening",
        "bloom",
        "blue",
        "celebrate",
        "coexistence",
        "compassion",
        "confrontation",
        "corrosion",
        "destination",
        "disposition",
        "embolden",
        "epiphany",
        "excavation",
        "feast",
        "fields",
        "flourish",
        "flurry",
        "gathering",
        "horizon",
        "humanity",
        "redemption",
        "transmogrification",
        "others",
        
      ]
    }
  },
  methods: {
    handleSlideClick (dataset) {
      console.log(dataset.index, dataset.name)
    },

    about() {
      this.$router.push('/')
    },

    press() {
      this.$router.push('/press')
    },

    exhibits() {
      this.$router.push('/exhibits')
    },

    contact() {
      this.$router.push('/contact')
    },

    goTo(page) {
      this.$router.push('/works/' + page)
    }
  }
}
</script>
<style scoped>
.title{
  color: black !important;
  font-weight: bolder !important;
  font-size: 20px;
}
.nav .active{
  color: black !important;
  font-weight: bolder !important;
}

.footer {
  background-color:black;
  width:100%;
  padding:30px;
  color:#666;
}

.nav-link {
  color:#a8afb7;
}

.active .nav-link {
  color:#181819;
  font-weight:bold;
}

.home {
  padding:20px 50px;
}

.works-wrapper {
  padding:0px 100px;
}

.h1 {
  font-size:40px;
  font-family:'Montserrat-Bold' !important;
}

.work {
  margin:20px;
  opacity:1;
  transition:.4s;
}

.work:hover {
  
  opacity: 1;
}

@media screen and (max-width:800px) {
  .h1 {
    font-size:30px;
  }
}

@media screen and (max-width:450px) {
  .works-wrapper {
    padding: 0px;
  }

  .h1 {
    font-size:25px;
  }
}

@media screen and (max-width:380px) {
  .home {
    padding:20px 30px;
  }
}
</style>
