<template>
  <div>
    <div class="home">
      <div class="justify-content-between nav">
        <b-nav class="justify-content-start" style="width:33%;">
          <b-nav-item @click="press">Press</b-nav-item>
          <b-nav-item>Exhibits</b-nav-item>
        </b-nav>
        <div style="width:33%;">
          <img alt="Vue logo" src="../../../assets/logo.png">
          <div class="title" @click="works"><h3>Works</h3></div>
        </div>
        <b-nav class="justify-content-end" style="width:33%;">
          <b-nav-item @click="about">About</b-nav-item>
          <b-nav-item @click="works">Works</b-nav-item>
          <b-nav-item @click="contact">Contact</b-nav-item>
        </b-nav>
      </div>
      <div class="works-wrapper mt-5">
        <div class="text-center">
          <h1 class="h1">Humanity</h1>
          <h4 class="quote mt-3">One of Sal’s many strengths in the continuous pursuit of her art is her
          dauntless and fiery curiosity to dive into different muses - florals, abstract shapes, and people.</h4>
          <h4 class="quote mt-3">As showcased in Humanity, people are as varied, juxtaposed, and playful as the colors
          (from muted and gloomy to light and vivid) and texture (from flat to coarse). </h4>
        </div>
        <div class="d-flex flex-wrap justify-content-around mt-5">
          <div class="work" @click="please()">
            <img src="../../../assets/Humanity/Please..png" alt="" class="work-img">
            <hr>
            <h5 class="work-title mt-3">Please.</h5>
            <p class="work-info">24 x 20 inches</p>
            <p class="work-info">Acrylic on Canvas</p>
          </div>
          <div class="work" @click="loveMe()">
            <img src="../../../assets/Humanity/Love Me..png" alt="" class="work-img">
            <hr>
            <h5 class="work-title mt-3">Love Me.</h5>
            <p class="work-info">24 x 20 inches</p>
            <p class="work-info">Acrylic on Canvas</p>
          </div>
          <div class="work" @click="studyingKandinsky()">
            <img src="../../../assets/Humanity/Studying Kandinsky..png" alt="" class="work-img">
            <hr>
            <h5 class="work-title mt-3">Studying Kandinsky.</h5>
            <p class="work-info">4.5 x 3.5 ft</p>
            <p class="work-info">Oil and Acrylic on Canvas</p>
          </div>
          <div class="work" @click="samuel()">
            <img src="../../../assets/Humanity/Samuel..png" alt="" class="work-img">
            <hr>
            <h5 class="work-title mt-3">Samuel.</h5>
            <p class="work-info">22 x 18 inches</p>
            <p class="work-info">Acrylic on Canvas</p>
          </div>
          <div class="work" @click="selfPortrait()">
            <img src="../../../assets/Humanity/Self Portrait..png" alt="" class="work-img">
            <hr>
            <h5 class="work-title mt-3">Self Portrait.</h5>
            <p class="work-info">48 x 36 inches</p>
            <p class="work-info">Mixed media on canvas</p>
          </div>
          <div class="work" @click="untitled()">
            <img src="../../../assets/Humanity/Untitled..png" alt="" class="work-img">
            <hr>
            <h5 class="work-title mt-3">Untitled.</h5>
            <p class="work-info">27.5 x 19.5 inches</p>
            <p class="work-info">Oil and Acrylic on Canvas</p>
          </div>
          <div class="work" @click="me()">
            <img src="../../../assets/Humanity/Me.png" alt="" class="work-img">
            <hr>
            <h5 class="work-title mt-3">Me.</h5>
            <p class="work-info">24 x 24 inches</p>
            <p class="work-info">Acrylic on Canvas</p>
          </div>
          <div class="work" @click="cheerUpKatQ()">
            <img src="../../../assets/Humanity/Cheer Up Kat Q.png" alt="" class="work-img">
            <hr>
            <h5 class="work-title mt-3">Cheer Up Kat Q.</h5>
            <p class="work-info">14 x 11 inches</p>
            <p class="work-info">Acrylic on Canvas</p>
          </div>
          <div class="work" @click="untitled2()">
            <img src="../../../assets/Humanity/Untitled 2.png" alt="" class="work-img">
            <hr>
            <h5 class="work-title mt-3">Untitled 2.</h5>
            <p class="work-info">14 x 11 inches</p>
            <p class="work-info">Acrylic on Canvas</p>
          </div>
          <div class="work">
            <img src="../../../assets/Humanity/Asset 2.png" alt="" class="work-img">
            <hr>
          </div>
          <div class="work">
            <img src="../../../assets/Humanity/Asset 1.png" alt="" class="work-img">
            <hr>
          </div>
          <div class="work">
            <img src="../../../assets/Humanity/Asset 3.png" alt="" class="work-img">
            <hr>
          </div>
          <div class="work" @click="samuel2()">
            <img src="../../../assets/Humanity/Samuel.png" alt="" class="work-img">
            <hr>
            <h5 class="work-title mt-3">Samuel</h5>
            <p class="work-info">22 x 18 inches</p>
            <p class="work-info">Oil on canvas</p>
          </div>
          <div class="work">
            <img src="../../../assets/Humanity/Asset 4.png" alt="" class="work-img">
            <hr>
          </div>
          <div class="work">
            <img src="../../../assets/Humanity/Asset 5.png" alt="" class="work-img">
            <hr>
          </div>
          <div class="work" @click="selfie()">
            <img src="../../../assets/Humanity/Asset 99.png" alt="" class="work-img">
            <hr>
            <h5 class="work-title mt-3">Selfie</h5>
            <p class="work-info">36 x 24 in.</p>
            <p class="work-info">Mixed media on canvas</p>
          </div>
          <div class="work" @click="firenzeMuse()">
            <img src="../../../assets/Humanity/Firenze Muse.png" alt="" class="work-img">
            <hr>
            <h5 class="work-title mt-3">Firenze Muse</h5>
            <p class="work-info">15 x 15 inches</p>
            <p class="work-info">Mixed media on canvas</p>
          </div>
          <div class="work" @click="untitled3()">
            <img src="../../../assets/Humanity/Untitled 3.png" alt="" class="work-img">
            <hr>
            <h5 class="work-title mt-3">Untitled</h5>
            <p class="work-info">48 x 36 inches</p>
            <p class="work-info">Mixed media on canvas</p>
          </div>
          <div class="work"  @click="womanOnATrain()">
            <img src="../../../assets/Humanity/Woman on a Train.png" alt="" class="work-img">
            <hr>
            <h5 class="work-title mt-3">Woman on a Train</h5>
            <p class="work-info">18 x 22 inches</p>
            <p class="work-info">Acrylic on canvas</p>
          </div>
          <div class="work">
            <img src="../../../assets/Humanity/Details forthcoming.png" alt="" class="work-img">
            <hr>
            <h5 class="work-title mt-3">Details forthcoming</h5>
          </div>
          <div class="work" @click="untitled4()">
            <img src="../../../assets/Humanity/Asset 98.png" alt="" class="work-img">
            <hr>
            <h5 class="work-title">Untitled</h5>
            <p class="work-info">28.5 x 36 inches</p>
            <p class="work-info">Mixed media on canvas</p>
          </div>
          <div class="work" @click="fallen()">
            <img src="../../../assets/Humanity/Asset 97.png" alt="" class="work-img">
            <hr>
            <h5 class="work-title mt-3">Fallen</h5>
            <p class="work-info">36 x 48 inches</p>
            <p class="work-info">Mixed media on canvas</p>
          </div>
          <div class="work" @click="ponder()">
            <img src="../../../assets/Humanity/Asset 94.png" alt="" class="work-img">
            <hr>
            <h5 class="work-title mt-3">Ponder</h5>
            <p class="work-info">28.5 x 36 inches</p>
            <p class="work-info">Mixed media on canvas</p>
          </div>
          <div class="work" @click="village()">
            <img src="../../../assets/Humanity/Asset 95.png" alt="" class="work-img">
            <hr>
            <h5 class="work-title mt-3">Village</h5>
            <p class="work-info">42 x 55 inches</p>
            <p class="work-info">Mixed media on canvas</p>
          </div>
          <div class="work" @click="dreamers()">
            <img src="../../../assets/Humanity/Asset 96.png" alt="" class="work-img">
            <hr>
            <h5 class="work-title mt-3">Dreamers</h5>
            <p class="work-info">36 x 28.5 inches</p>
            <p class="work-info">Mixed media on canvas</p>
          </div>
          <div class="work" @click="two018()">
            <img src="../../../assets/Humanity/Asset 91.png" alt="" class="work-img">
            <hr>
            <h5 class="work-title mt-3">2018</h5>
            <p class="work-info">60 x 50 inches</p>
            <p class="work-info">Mixed media on canvas</p>
          </div>
          <div class="work" @click="agony()">
            <img src="../../../assets/Humanity/Asset 89.png" alt="" class="work-img">
            <hr>
            <h5 class="work-title mt-3">Agony</h5>
            <p class="work-info">16 x 12 inches</p>
            <p class="work-info">Acrylic on canvas</p>
          </div>
          <div class="work" @click="museSeries3()">
            <img src="../../../assets/Humanity/Muse series No. 3.png" alt="" class="work-img">
            <hr>
            <h5 class="work-title mt-3">Muse series: No. 3</h5>
            <p class="work-info">22 x 18 inches</p>
            <p class="work-info">Acrylic and oil on canvas</p>
          </div>
          <div class="work" @click="museSeries2()">
            <img src="../../../assets/Humanity/Muse Series No. 2.png" alt="" class="work-img">
            <hr>
            <h5 class="work-title mt-3">Muse Series No. 2</h5>
            <p class="work-info">22 x 18 inches</p>
            <p class="work-info">Mixed media on canvas</p>
          </div>
          <div class="work" @click="muse()">
            <img src="../../../assets/Humanity/Muse.png" alt="" class="work-img">
            <hr>
            <h5 class="work-title mt-3">Muse</h5>
            <p class="work-info">22 x 18 inches</p>
            <p class="work-info">Acrylic on canvas</p>
          </div>
          <div class="work" @click="rival()">
            <img src="../../../assets/Humanity/Asset 88.png" alt="" class="work-img">
            <hr>
            <h5 class="work-title mt-3">Rival</h5>
            <p class="work-info">36 x 36 inches</p>
            <p class="work-info">Acrylic on canvas</p>
          </div>
          <div class="work" @click="littleLilacGirl()">
            <img src="../../../assets/Humanity/Asset 93.png" alt="" class="work-img">
            <hr>
            <h5 class="work-title mt-3">Little Lilac Girl</h5>
            <p class="work-info">36 x 28.5 inches</p>
            <p class="work-info">Mixed media on canvas</p>
          </div>
          <div class="work" @click="broken()">
            <img src="../../../assets/Humanity/Broken.png" alt="" class="work-img">
            <hr>
            <h5 class="work-title mt-3">Broken</h5>
            <p class="work-info">36 x 36 inches</p>
            <p class="work-info">Acrylic on canvas</p>
          </div>
          <div class="work" @click="untitled5()">
            <img src="../../../assets/Humanity/Asset 92.png" alt="" class="work-img">
            <hr>
            <h5 class="work-title mt-3">Untitled</h5>
            <p class="work-info">12 x 24 inches</p>
            <p class="work-info">Mixed media</p>
          </div>
          <div class="work" @click="interruptedDream()">
            <img src="../../../assets/Humanity/Asset 90.png" alt="" class="work-img">
            <hr>
            <h5 class="work-title mt-3">Interrupted Dream</h5>
            <p class="work-info">36 x 24 inches</p>
            <p class="work-info">Acrylic on canvas</p>
          </div>
          <div class="work" @click="untitled6()">
            <img src="../../../assets/Humanity/Untitled.png" alt="" class="work-img">
            <hr>
            <h5 class="work-title mt-3">Untitled</h5>
            <p class="work-info">28.5 x 36 inches</p>
            <p class="work-info">Mixed media on canvas on board</p>
          </div>
          <div class="work" @click="miniVision()">
            <img src="../../../assets/Humanity/Mini Vision.png" alt="" class="work-img">
            <hr>
            <h5 class="work-title mt-3">Mini Visión.</h5>
            <p class="work-info">24 x 36 inches</p>
            <p class="work-info">Acrylic on canvas</p>
          </div>
          <div class="work" @click="son()">
            <img src="../../../assets/Humanity/Son..png" alt="" class="work-img">
            <hr>
            <h5 class="work-title mt-3">Son.</h5>
            <p class="work-info">24 x 18 inches</p>
            <p class="work-info">Acrylic on canvas</p>
          </div>
        </div>
      </div>
    </div>
    <div class="footer mt-5">
      <h3 class="text-white">&copy; 2022 SALPONCEENRILE.COM</h3>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
    
  },
  data() {
    return {
      
    }
  },
  methods: {
    handleSlideClick (dataset) {
      console.log(dataset.index, dataset.name)
    },

    about() {
      this.$router.push('/')
    },

    press() {
      this.$router.push('/press')
    },

    works() {
      this.$router.push('/works')
    },

    contact() {
      this.$router.push('/contact')
    },

    please() {
      this.$router.push('/works/humanity/please')
    },

    loveMe() {
      this.$router.push('/works/humanity/love-me')
    },

    studyingKandinsky() {
      this.$router.push('/works/humanity/studying-kandinsky')
    },

    museSeries3() {
      this.$router.push('/works/humanity/muse-series-no-3')
    },

    samuel() {
      this.$router.push('/works/humanity/samuel')
    },

    selfPortrait() {
      this.$router.push('/works/humanity/self-portrait')
    },

    untitled() {
      this.$router.push('/works/humanity/untitled')
    },

    me() {
      this.$router.push('/works/humanity/me')
    },

    cheerUpKatQ() {
      this.$router.push('/works/humanity/cheer-up-kat-q')
    },

    untitled2() {
      this.$router.push('/works/humanity/untitled-2')
    },

    samuel2() {
      this.$router.push('/works/humanity/samuel-2')
    },

    selfie() {
      this.$router.push('/works/humanity/selfie')
    },

    firenzeMuse() {
      this.$router.push('/works/humanity/firenze-muse')
    },

    untitled3() {
      this.$router.push('/works/humanity/untitled-3')
    },

    womanOnATrain() {
      this.$router.push('/works/humanity/woman-on-a-train')
    },

    untitled4() {
      this.$router.push('/works/humanity/untitled-4')
    },

    fallen() {
      this.$router.push('/works/humanity/fallen')
    },

    ponder() {
      this.$router.push('/works/humanity/ponder')
    },

    village() {
      this.$router.push('/works/humanity/village')
    },

    dreamers() {
      this.$router.push('/works/humanity/dreamers')
    },

    two018() {
      this.$router.push('/works/humanity/2018')
    },

    agony() {
      this.$router.push('/works/humanity/agony')
    },

    museSeries2() {
      this.$router.push('/works/humanity/muse-series-no-2')
    },

    muse() {
      this.$router.push('/works/humanity/muse')
    },

    rival() {
      this.$router.push('/works/humanity/rival')
    },

    littleLilacGirl() {
      this.$router.push('/works/humanity/little-lilac-girl')
    },

    broken() {
      this.$router.push('/works/humanity/broken')
    },

    untitled5() {
      this.$router.push('/works/humanity/untitled-5')
    },

    interruptedDream() {
      this.$router.push('/works/humanity/interrupted-dream')
    },

    untitled6() {
      this.$router.push('/works/humanity/untitled-6')
    },

    miniVision() {
      this.$router.push('/works/humanity/mini-vision')
    },

    son() {
      this.$router.push('/works/humanity/son')
    },
  }
}
</script>
<style scoped>
.title{
  color: black !important;
  font-weight: bolder !important;
  font-size: 20px;
}
.nav .active{
  color: black !important;
  font-weight: bolder !important;
}

.footer {
  background-color:black;
  width:100%;
  padding:30px;
  color:#666;
}

.nav-link {
  color:#a8afb7;
}

.active .nav-link {
  color:#181819;
  font-weight:bold;
}

.home {
  padding:20px 50px;
}

.works-wrapper {
  padding:0px 100px;
}

.h1 {
  font-size:40px;
  font-family:'Montserrat-SemiBold' !important;
}

.work {
  margin:30px;
  opacity: 1;
  transition:.4s;
  width:28em;
  height:38em;
  text-align:left;
  font-family:'Montserrat-Medium' !important;
  border-radius:10px;
  box-shadow: 5px 10px 18px #888888;
  padding:20px;
}

.work-img {
  height:28em;
  width:25em;
  object-fit: contain;
}

.work:hover {
  opacity:1;
}

.work-title {
  font-size:25px;
  opacity: 1;
}

.work-info {
  margin-bottom:0;
  font-size:17px;
}

.quote {
  font-size:25px;
  font-family:'Montserrat-Regular' !important;
}

@media screen and (max-width:800px) {
  .h1 {
    font-size:30px;
  }
}

@media screen and (max-width:450px) {
  .works-wrapper {
    padding: 0px;
  }

  .h1 {
    font-size:25px;
  }

  .work {
    margin:30px;
    opacity: 1;
    transition:.4s;
    width:25em;
    height:35em;
    text-align:left;
    font-family:'Montserrat-Medium' !important;
    border-radius:10px;
    box-shadow: 5px 10px 18px #888888;
    padding:20px;
  }

  .work-img {
    height:25em;
    width:22em;
    object-fit: contain;
  }
}

@media screen and (max-width:380px) {
  .home {
    padding:20px 30px;
  }
}


</style>
