<template>
  <div>
    <div class="home">
      <div class="justify-content-between nav">
        <b-nav class="justify-content-start" style="width:33%;">
          <b-nav-item class="active">Press</b-nav-item>
          <b-nav-item @click="exhibits">Exhibits</b-nav-item>
        </b-nav>
        <div style="width:33%;">
          <img alt="Vue logo" src="../assets/logo.png">
          <div class="title"><h3>Press</h3></div>
        </div>
        <b-nav class="justify-content-end" style="width:33%;">
          <b-nav-item @click="about">About</b-nav-item>
          <b-nav-item @click="works">Works</b-nav-item>
          <b-nav-item @click="contact">Contact</b-nav-item>
        </b-nav>
      </div>
      <div class="news-wrapper mx-auto mt-5">
        <div class="news d-flex flex-wrap justify-content-around mx-auto mb-5" style="width:100%;">
          <div class="news-img" style="width:35em;">
            <img src="../assets/Exhibits/thegreyspacesal.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left" style="width:35em;">
            <h1 style="font-weight:bold;">Art Assistance: Sal Ponce-Enrile Aims To Help And Inspire In Her 3rd Show</h1>
            <p class="author">by TheGreySpace.ph</p>
            <h5 class="mt-3 h5-2">Exploring the Complexity of the Human Experience through the Art of Sal Ponce Enrile</h5>

            <h5 class="mt-3">Sal Ponce Enrile is an artist who uses her work to express the complexities of 
              her experiences and to explore her authentic personal self. As a former legislator, 
              she knows the value of speaking truthfully and openly, and her artwork is a testament to that ethos. 
              Through her paintings, Sal is able to give voice to her hopes, dreams, fears, and the things she values most in life, 
              such as her love of God and family.</h5>


            <button class="btn btn-read-more mt-4" @click="readMore('https://www.thegreyspaceph.com/blogs/featured-artists/exploring-the-complexity-of-the-human-experience-through-the-art-of-sal-ponce-enrile')">READ MORE</button>
          </div>
        </div>
        <hr>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mb-5" style="width:100%;">
          <div class="news-img" style="width:35em;">
            <img src="../assets/Press/Asset 9.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left" style="width:35em;">
            <h1 style="font-weight:bold;">Art Assistance: Sal Ponce-Enrile Aims To Help And Inspire In Her 3rd Show</h1>
            <p class="author">by Lifestyle Asia</p>
            <h5 class="mt-3 h5-2">Proceeds from her works go to different charities that aid
            communities in need during these challenging times.</h5>

            <h5 class="mt-3">“We need beauty now more than ever,” says Sal Ponce-Enrile. 
            The Congresswoman turned artist wishes to impart this message through her third 
            solo exhibit at Art Elaan, which opened at Ayala Malls Manila Bay over the weekend.</h5>

            <h5 class="mt-3">Titled as “Transformation,” the show gives a glimpse of
            Ponce-Enrile’s inspirations as a novel artist. With these
            works, she explores her creative expression by experimenting with various forms and techniques. This gave
            her to have a broader perspective, allowing her to
            create with more intention and confidence.</h5>

            <h5 class="mt-3">“Transformation” is a hallmark of her artistic identity,
            carrying the message that art is never stagnant.</h5>

            <button class="btn btn-read-more mt-4" @click="readMore('https://lifestyleasia.onemega.com/art-assistance-sal-ponce-enrile-aims-to-help-and-inspire-in-her-3rd-show/')">READ MORE</button>
          </div>
        </div>
        <hr>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:35em;">
            <img src="../assets/Press/Asset 25.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left" style="width:35em;">
            <h1 style="font-weight:bold;">Sally Ponce-Enrile: Up and Close and Personal</h1>

            <h5 class="mt-3">Meet Sally Ponce Enrile — an inspiring woman just like her paintings. That is actually an understatement. She is inspiring because
            she radiates humble confidence and grace. Sally has that rare
            quality of consistently acting and speaking from a place of truthful
            gratitude from the heart; being gracious toward others at all
            times. She exudes true modest assurance, and yes, that is inspiring.</h5>

            <h5 class="mt-3">Sally exudes compassion and kindness by dealing with situations and people with altruism in her heart.</h5>

            <button class="btn btn-read-more mt-4" @click="readMore('https://canadianinquirer.net/2021/03/01/sally-ponce-enrile-up-close-and-personal/')">READ MORE</button>
          </div>
        </div>
        <hr>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:35em;">
            <img src="../assets/Press/Asset 24.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left" style="width:35em;">
            <h1 style="font-weight:bold;">Art Collections by Rossocinabro</h1>

            <h5 class="mt-3">Sal Ponce-Enrile joins Art Collections, a group exhibition where
            selected works from all over the world will be on display at Rossocinabro Gallery in Rome, 
            underlining how much Art, as the energy of the deep, has 
            much to teach and say about us and what surrounds us.</h5>

            <h5 class="mt-3">The artworks selection was curated by Joe Hansen and Cristina Madini.</h5>

            <button class="btn btn-read-more mt-4" @click="readMore('https://www.artrabbit.com/events/art-collections')">READ MORE</button>
          </div>
        </div>
        <hr>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:35em;">
            <img src="../assets/Press/Asset 23.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left" style="width:35em;">
            <h1 style="font-weight:bold;">Ex-solon turned painter Sal Ponce Enrile returns to Art Basel Miami ‘19</h1>

            <h5 class="mt-3">MIAMI — Former Philippine legislator turned painter Sal Ponce
            Enrile will represent Filipino artistry once again in the international
            stage at the 2019 Art Basel Miami Beach Fair in Miami, Florida on
            December 4–8.</h5>

            <h5 class="mt-3">Art Basel Miami Beach is North America’s most
            comprehensive international contemporary art fair with over 268
            galleries from 35 countries showcasing works by more than 4,000
            artists</h5>

            <button class="btn btn-read-more mt-4" @click="readMore('https://usa.inquirer.net/48818/ex-solon-turned-painter-sal-ponce-enrile-returns-to-art-basel-miami-19')">READ MORE</button>
          </div>
        </div>
        <hr>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:35em;">
            <img src="../assets/Press/Asset 22.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left" style="width:35em;">
            <h1 style="font-weight:bold;">Art Basel onLifestyle Asia</h1>

            <h5 class="mt-3">Sal Ponce Enrile joins the Art Basel Miami Beach Fair 2019 with her
            three artworks: “Village,” “Dreamers,” and the crowd favorite from
            her first solo show, “Redemption.” Sal shares she painted “Redemption” with the intent of “purging all negativity that I initially experienced as an emerging artist.” The artwork is an encouragement for
            the artist, as she continues on her journey of breaking into the
            international scene.</h5>

            <button class="btn btn-read-more mt-4" @click="readMore('https://www.instagram.com/p/B5ulPGgnTE_/?fbclid=IwAR2GTZN9_6nYIRonlraeopTwzzog4QKF8ns6vqnR7XgsTYheB8yW6JqUhXM&utm_source=ig_web_button_share_sheet')">READ MORE</button>
          </div>
        </div>
        <hr>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:35em;">
            <img src="../assets/Press/Asset 21.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left" style="width:35em;">
            <h1 style="font-weight:bold;">Filipino Artistry Showcased at North America’s Biggest Art Fair</h1>

            <h5 class="mt-3">Former Congresswoman turned Painter Sal Ponce Enrile is set to
            represent Filipino artistry once again in the
            international stage at the 2019 Art Basel Miami Beach Fair in
            Miami, Florida on December 4–8, 2019. Art Basel Miami Beach is
            North America’s most comprehensive international contemporary
            art fair with over 268 galleries from 35 countries showcasing works
            by more than 4,000 artists.</h5>

            <button class="btn btn-read-more mt-4" @click="readMore('https://digital-news.themanilaexpress.com/filipino-artistry-showcased-at-north-americas-biggest-art-fair/?%20%20Local%20Media')">READ MORE</button>
          </div>
        </div>
        <hr>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:35em;">
            <img src="../assets/Press/Asset 20.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left" style="width:35em;">
            <h1 style="font-weight:bold;">From One Dream To Another: Sal Ponce Enrile’s Journey As An Artist</h1>
            <p class="author">By Katherine Alamares of Lifestyle Asia</p>

            <h5 class="mt-3">“Journey” is Sal’s second solo exhibit. It depicted thirteen semi-figurative symbolist works and twelve abstract expressionist paintings. The centerpiece titled “Journey” is a symbolism of “anyone’s
            journey through life,” Sal
            explains. Starting from the crucifix, it represents leaving the past.
            Next is the cathedral as prayers for guidance, flowers for gratitude,
            and boats for traveling to unfamiliar places. The four figures are
            Sal’s family. At the top left corner is a figure of Jesus Christ, Sal’s
            anchor in life.</h5>

            <h5 class="mt-3">The rest of the abstract paintings depict her style as ethereal and
            celestial. Wisps of gold accents and vibrant play of colors dominate her works. The iridescent hues create a mesmerizing effect for
            the viewers. It evokes a feeling of nostalgia as if seeing an old and
            beautiful memory. This impact is similar to the title piece with its
            narrative of Sal’s life journey. All the other works in the exhibition
            invite viewers to charge it with their own
            memories and experiences.</h5>

            <button class="btn btn-read-more mt-4" @click="readMore('https://lifestyleasia.onemega.com/sal-ponce-enrile-artist-painting/')">READ MORE</button>
          </div>
        </div>
        <hr>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:35em;">
            <img src="../assets/Press/Asset 19.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left" style="width:35em;">
            <h1 style="font-weight:bold;">Arts & Culture: Filipina Artist Sal Ponce Enrile to Hold 2nd Solo Art Exhibit Centered on Her Journey from Legislator to Artist</h1>
            <p class="author">By Adobo Magazine</p>

            <h5 class="mt-3">MANILA, PHILIPPINES – Fresh from her stint in <strong>ArtExpo New York
            2019</strong>, Former Congresswoman turned Painter Sal Ponce Enrile is
            holding her Second Solo Art Exhibit</h5>

            <h5 class="mt-3">Named as “Journey: Sal Ponce Enrile,” the art show will open to the
            public on June 16 until July 4, 2019 at
            <strong>Art Cube Gallery</strong>, Karrivin Plaza, 2/F Building B, 2316, 1232 Chino
            Roces Ave, Makati. VIP guests are local art connoisseurs, Mr. Paulino Que and Mrs. Hetty Que, who will grace the exhibit’s ribbon
            cutting.</h5>

            <button class="btn btn-read-more mt-4" @click="readMore('https://www.adobomagazine.com/philippine-news/arts-culture-filipina-artist-sal-ponce-enrile-to-hold-2nd-solo-art-exhibit-centered-on-her-journey-from-legislator-to-artist/')">READ MORE</button>
          </div>
        </div>
        <hr>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:35em;">
            <img src="../assets/Press/Asset 18.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left" style="width:35em;">
            <h1 style="font-weight:bold;">Sal Ponce Enrile Holds 2nd Solo Art Exhibit</h1>
            <p class="author">By Philippine Tatler</p>

            <h5 class="mt-3">Fresh from her stint in ArtExpo New York 2019, Sal Ponce Enrile unveiled her newest solo exhibit</h5>

            <h5 class="mt-3">Displayed in the Art Cube Gallery last June, Sal’s newest works
            focused on her journey in pursuing her life-long dream to be an
            artist.</h5>

            <button class="btn btn-read-more mt-4" @click="readMore('https://www.tatlerasia.com/lifestyle/arts/sal-ponce-enrile-holds-2nd-solo-art-exhibit')">READ MORE</button>
          </div>
        </div>
        <hr>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:35em;">
            <img src="../assets/Press/Asset 17.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left" style="width:35em;">
            <h1 style="font-weight:bold;">Sal Ponce Enrile gets candid about leaving the world of politics for art</h1>
            <p class="author">By B. Del Rio of Preen Inquirer</p>

            <h5 class="mt-3">Art and politics—some would say these are worlds apart. But Sal
            Ponce Enrile was able to effectively navigate the two and come
            out better for them</h5>

            <h5 class="mt-3">To the people of the 1st district of Cagayan, Sal may be more
            known as a public servant, having finished two terms as their representative in the 14th and 16th congress. But before she became
            a congresswoman, Sal was primarily drawn to the arts. As a sickly
            child, she was advised to stay indoors, where she whiles away time
            by sketching, drawing and painting. But she didn’t pursue her love
            for the arts until much later</h5>

            <h5 class="mt-3">From the time she resumed painting in 2017, there was no day
            that she has not painted. This discipline and commitment towards
            her art proved favorable. Sal’s artworks are now displayed in private collections in the Philippines, Germany, New York, California,
            and Spain. She is currently representing Agora Galley New York
            and Dragon Art in Marbella, Spain. Her name is also becoming
            known at local and international exhibitions.</h5>

            <button class="btn btn-read-more mt-4" @click="readMore('https://preen.ph/97231/sal-ponce-enrile-gets-candid-about-leaving-the-world-of-politics-for-art')">READ MORE</button>
          </div>
        </div>
        <hr>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:35em;">
            <img src="../assets/Press/Asset 16.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left" style="width:35em;">
            <h1 style="font-weight:bold;">The journey of an intuitive artist and her controlled chaos</h1>
            <p class="author">By JT Nisay of Business Mirror</p>

            <h5 class="mt-3">AS a sickly child, while her friends ran and skipped and jumped,
            Sal Ponce Enrile stayed mostly indoors. The threat of having an
            asthma attack often kept her in a room, without a sweat on her
            face but a pen in her hand, as she chose to while away time
            sketching, drawing and painting</h5>

            <h5 class="mt-3">Taking shape was a future as an artist. But then, as Enrile put it, “life happened.”</h5>

            <button class="btn btn-read-more mt-4" @click="readMore('https://businessmirror.com.ph/2019/07/02/the-journey-of-an-intuitive-artist-and-her-controlled-chaos/')">READ MORE</button>
          </div>
        </div>
        <hr>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:35em;">
            <img src="../assets/Press/Asset 15.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left" style="width:35em;">
            <h1 style="font-weight:bold;">Philippinische Meisterwerke an der SWISSARTEXPO Sal Ponce Enrile an der SWISSARTEXPO</h1>

            <h5 class="mt-3">Zürich (ptp003/25.05.2019/11:00) - Die philippinische Künstlerin Sal Ponce Enrile kündigt
            heute an, dass sie im August 2019 mit ihrem Kunstwerk "Village" im Rahmen des ARTBOX.PROJECT Zürich 1.0 
            an dem Kunstfestival SWISSARTEXPO dabei sein wird. Die Ausstellung, 
            zu der rund 400'000 Besucher erwartet werden, wird vom 15. bis 19. August 2019 in der Zürcher 
            Hauptbahnhofshalle stattfinden.</h5>

            <button class="btn btn-read-more mt-4" @click="readMore('https://www.pressetext.com/news/philippinische-meisterwerke-an-der-swissartexpo.html')">READ MORE</button>
          </div>
        </div>
        <hr>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:35em;">
            <img src="../assets/Press/Asset 14.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left" style="width:35em;">
            <h1 style="font-weight:bold;">Capolavori filippini allo SWISSARTEXPO Sal Ponce Enrile allo SWISSARTEXPO</h1>

            <h5 class="mt-3">Zurich, Switzerland (ptp007/25.05.2019/11:20) - L'artista filippina
            Sal Ponce Enrile ha annunciato oggi la sua partecipazione al festival artistico SWISSARTEXPO nell'agosto 2019 con la sua opera
            "Village" nell'ambito di ARTBOX.PROJECT Zurich 1.0. La mostra,
            che dovrebbe attirare circa 400.000 visitatori, si svolgerà dal 15 al
            19 agosto 2019 nella sala della stazione centrale di Zurigo.</h5>

            <button class="btn btn-read-more mt-4" @click="readMore('http://www.newsfox.com/news/20190525007')">READ MORE</button>
          </div>
        </div>
        <hr>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:35em;">
            <img src="../assets/Press/Asset 13.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left" style="width:35em;">
            <h1 style="font-weight:bold;">Sal Ponce Enrile Empowers Through Her Art</h1>
            <p class="author">By Dragon</p>

            <h5 class="mt-3">A former dedicated legislator serving the people of her native
            Philippines, Dragon Artist Sal Ponce Enrile felt guided to transform
            her lifestyle to one that allowed her to pursue her passions in an
            authentic, liberating and personally empowering way.</h5>

            <h5 class="mt-3">Sal continuously strives to improve upon the creation of her
            unique style and identity through her art, melting away any barriers or facades around the different personas her artwork evokes. A
            woman of abstract expressionism, Sal is helping to pave the way
            for fellow groundbreaking female artists in the traditionally
            male-centric movement of abstract art.</h5>

            <button class="btn btn-read-more mt-4" @click="readMore('http://www.newsfox.com/news/20190525007')">READ MORE</button>
          </div>
        </div>
        <hr>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:35em;">
            <img src="../assets/Press/Asset 11.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left" style="width:35em;">
            <h1 style="font-weight:bold;">Legislator-Turned-Artist Sal Ponce Enrile Will Exhibit Her Work at the Prestigious Artexpo New York</h1>
            <p class="author">By EsquireMag</p>

            <h5 class="mt-3">The legislator-turned-painter is making waves in the international
            art scene. Currently, she’s preparing a gutsy display of expressionistic masterpieces for the 41st year of the world’s largest fine art
            trade show, the Artexpo New York, which will take place from April
            4 to 7.</h5>

            <button class="btn btn-read-more mt-4" @click="readMore('https://www.esquiremag.ph/culture/arts-and-entertainment/legislator-artist-sal-ponce-enrile-artexpo-new-york-adv-con-pr')">READ MORE</button>
          </div>
        </div>
        <hr>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:35em;">
            <img src="../assets/Press/Asset 26.jpg" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left" style="width:35em;">
            <h1 style="font-weight:bold;">Filipina painter tapped for prestigious Artexpo New York</h1>
            <p class="author">By INQUIRER.net US Bureau</p>

            <h5 class="mt-3">NEW YORK — Painter Sal Ponce Enrile was selected to exhibit her
            works in the prestigious Artexpo New York, April 4–7 the world’s
            largest fine art trade show, which shapes trends in art galleries
            worldwide.</h5>

            <button class="btn btn-read-more mt-4" @click="readMore('https://usa.inquirer.net/26840/filipina-painter-tapped-for-prestigious-artexpo-new-york')">READ MORE</button>
          </div>
        </div>
        <hr>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:35em;">
            <img src="../assets/Press/Asset 12.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left" style="width:35em;">
            <h1 style="font-weight:bold;">Filipina artist chosen to exhibit masterpieces in Prestigious Artexpo New York</h1>
            <p class="author">By Manila Standard</p>

            <h5 class="mt-3">Sal was picked by the internationally-acclaimed art
            gallery, Agora Gallery New York, to become one of their featured
            artists in the expo, after seeing her abstract work entitled “Retrospect” which used acrylic, mixed media, and a variety of textures.</h5>

            <h5 class="mt-3">“My curator for my firrst exhibit suggested that I should try submitting my portfolio to Agora Gallery in New York. I told myself I had
            nothing to lose and I did not really expect to be accepted,” she
            said.</h5>

            <h5 class="mt-3">“I received several e-mails that led me to different
            departments, one of which was a long list of questions about me
            and my work. Still, I figured, I will just keep
            replying. After this last e-mail, I got another one from the Director,
            welcoming me to Agora as one of their artists!”</h5>

            <button class="btn btn-read-more mt-4" @click="readMore('https://manilastandard.net/pop-life/291474/filipina-artist-chosen-to-exhibit-masterpieces-in-prestigious-artexpo-new-york.html')">READ MORE</button>
          </div>
        </div>
        <hr>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:35em;">
            <img src="../assets/Press/Asset 11.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left" style="width:35em;">
            <h1 style="font-weight:bold;">Filipino artists abound at the world’s largest fine art trade show</h1>
            <p class="author">By Nolisoli.ph</p>

            <h5 class="mt-3">For the past 40 years, Artexpo New York has been
            showcasing thousands of contemporary art works, once hosting
            pieces by Andy Warhol. Purportedly the venue where Keith Haring
            and Robert Rauschenberg got their start, it’s also the oldest trade
            show for fine art in the U.S and claims to be the world’s largest.
            The show is a huge tastemaker for the art industry: Going to it will
            give you a good sense on what the art world’s “next big thing” is
            going to be.</h5>

            <button class="btn btn-read-more mt-4" @click="readMore('https://nolisoli.ph/60603/filipino-artists-artexpo-new-york-2019-zacosta-20190403/')">READ MORE</button>
          </div>
        </div>
        <hr>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:35em;">
            <img src="../assets/Press/Asset 10.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left" style="width:35em;">
            <h1 style="font-weight:bold;">In Retrospect: The Making of an Artist</h1>
            <p class="author">By CNN Philippines</p>

            <h5 class="mt-3">Metro Manila (CNN Philippines) — Gazing into a work of art can at times, allow us to peer back in time.</h5>

            <h5 class="mt-3">That way, we can see how far we've come — a realization that can help individuals move forward.</h5>

            <h5 class="mt-3">For painter Sal Ponce Enrile, past experiences mirrored in her works make her see the world with fresh eyes</h5>

            <h5 class="mt-3">This inspired her to craft "Retrospect" - the expressionistic painting
            which paved the way for her to become the first Filipino artist to
            be featured in Artexpo New York, the world's largest gathering of
            artists and art enthusiasts.</h5>

            <button class="btn btn-read-more mt-4" @click="readMore('https://www.cnnphilippines.com/lifestyle/2019/3/30/In-Retrospect--The-Making-of-an-Artist.html')">READ MORE</button>
          </div>
        </div>
        <hr>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:35em;">
            <img src="../assets/Press/Asset 9.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left" style="width:35em;">
            <h1 style="font-weight:bold;">Sal of the earth</h1>
            <p class="author">By Daily Tribune</p>

            <h5 class="mt-3">Painter Sal Ponce Enrile was selected to exhibit her works in the
            highly prestigious Artexpo New York, the world’s largest fine art
            trade show, which shapes trends in art galleries worldwide this
            coming 4 to 7 April 2019.</h5>

            <h5 class="mt-3">Retrospect, just like her other paintings, are blurred-out or grayed over like memories of a forgotten era</h5>

            <h5 class="mt-3">Using acrylic, mixed media and a variety of textures on her abstract masterpiece entitled “Retrospect,” she was picked by the
            internationally acclaimed art gallery, Agora Gallery New York, to
            become one of their featured artists in the expo.</h5>

            <button class="btn btn-read-more mt-4">READ MORE</button>
          </div>
        </div>
        <hr>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:35em;">
            <img src="../assets/Press/Asset 8.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left" style="width:35em;">
            <h1 style="font-weight:bold;">Legislator-Turned Artist Sal Ponce Enrile Stirs Renewed Interest With Sophomore Show</h1>

            <h5 class="mt-3">Michelangelo painted the Sistine Chapel ceiling for four years; though with its painstaking detail, it’s easy to understand why. 
            What first-time creators have dealt with since time immemorial, however, is the dreaded sophomore slump.</h5>

            <h5 class="mt-3">Painter Sal Ponce Enrile comes to grips with this a mere two months after her first 
            ever solo show Awakening, which was also her public unveiling as an artist.</h5>

            <button class="btn btn-read-more mt-4" @click="readMore('https://www.esquiremag.ph/culture/arts-and-entertainment/former-legislator-sal-ponce-enrile-stirs-renewed-interest-with-sophomore-show-adv-con-pr')">READ MORE</button>
          </div>
        </div>
      </div>
    </div>
    <div class="footer mt-5">
      <h3 class="text-white">&copy; 2022 SALPONCEENRILE.COM</h3>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
    
  },
  methods: {
    handleSlideClick (dataset) {
      console.log(dataset.index, dataset.name)
    },

    about() {
      this.$router.push('/')
    },

    exhibits() {
      this.$router.push('/exhibits')
    },

    works() {
      this.$router.push('/works')
    },
  
    contact() {
      this.$router.push('/contact')
    },

    readMore(link) {
      window.open(link)
    }
  }
}
</script>
<style scoped>
.title{
  color: black !important;
  font-weight: bolder !important;
  font-size: 20px;
}
.nav .active{
  color: black !important;
  font-weight: bolder !important;
}
.quote{
  font-family:'Montserrat-SemiBold', sans-serif !important;
  font-size: 50px !important;
  text-align: center !important;
  display: flex !important;
  justify-content: center !important;

}

.footer {
  background-color:black;
  width:100%;
  padding:30px;
  color:#666;
}

.text-left {
  text-align:left !important;
}

.profile p {
  font-family:'Montserrat-Regular' !important;
  color:#666666;
  font-size:20px;
}

.profile h3 {
  font-family:'Montserrat-Bold' !important;
  color:#333;
  font-size:35px;
}

.btn-talk {
  font-family:'Montserrat-Regular' !important;
  padding:20px;
  border-radius:50px;
  border:2px #333 solid;
  width:15em;
  font-size:20px;
}

.btn-talk:hover {
  font-family:'Montserrat-Regular' !important;
  padding:20px;
  border-radius:50px;
  border:2px #333 solid;
  background:#333;
  color:white;
  width:15em;
  font-size:20px;
}

.nav-link {
  color:#a8afb7;
}

.active .nav-link {
  color:#181819;
  font-weight:bold;
}

.home {
  padding:20px 50px;
}

.author {
  font-family:'Montserrat-Light' !important;
  color:#898989;
}

.h5-2 {
  font-family:'Montserrat-SemiBold' !important;
  color:#898989;
}

h5 {
  font-family:'Montserrat-Regular' !important;
  color:#898989;
}

.btn-read-more {
  border:2px #333 solid;
  padding:15px;
  border-radius:0px;
}

.btn-read-more:hover {
  border:2px #333 solid;
  background:#333;
  color:white;
  padding:15px;
  border-radius:0px;
}

.news {
  margin-bottom:20px !important;
}

@media screen and (max-width:380px) {
  .home {
    padding:20px 30px;
  }
}

</style>
