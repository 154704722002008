import Vue from 'vue'
import VueRouter from 'vue-router'
import About from '../views/About.vue'
import Press from '../views/Press.vue'
import Exhibits from '../views/Exhibits.vue'
import Works from '../views/Works.vue'
import Contact from '../views/Contact.vue'
import Humanity from '../views/works/humanity/Humanity.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'About',
    component: About
  },

  {
    path: '/press',
    name: 'Press',
    component: Press
  },

  {
    path: '/exhibits',
    name: 'Exhibits',
    component: Exhibits
  },

  {
    path: '/works',
    name: 'Works',
    component: Works
  },

  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },

  {
    path: '/works/humanity',
    name: 'Humanity',
    component: Humanity
  },

  {
    path: '/works/humanity/please',
    name: 'Please',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/humanity/'Please.'.vue")
  },

  {
    path: '/works/humanity/love-me',
    name: 'LoveMe',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/humanity/'Love Me.'.vue")
  },

  {
    path: '/works/humanity/studying-kandinsky',
    name: 'StudyingKandinsky',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/humanity/'Studying Kandinsky.'.vue")
  },

  {
    path: '/works/humanity/samuel',
    name: 'Samuel',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/humanity/'Samuel.'.vue")
  },

  {
    path: '/works/humanity/self-portrait',
    name: 'SelfPortrait',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/humanity/'Self Portrait.'.vue")
  },

  {
    path: '/works/humanity/untitled',
    name: 'Untitled',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/humanity/'Untitled.'.vue")
  },

  {
    path: '/works/humanity/me',
    name: 'Me',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/humanity/'Me.'.vue")
  },

  {
    path: '/works/humanity/cheer-up-kat-q',
    name: 'CheerUpKatQ',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/humanity/'Cheer Up Kat Q.'.vue")
  },

  {
    path: '/works/humanity/untitled-2',
    name: 'Untitled2',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/humanity/'Untitled 2.'.vue")
  },

  {
    path: '/works/humanity/samuel-2',
    name: 'Samuel2',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/humanity/'Samuel'.vue")
  },

  {
    path: '/works/humanity/selfie',
    name: 'Selfie',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/humanity/'Selfie'.vue")
  },

  {
    path: '/works/humanity/firenze-muse',
    name: 'FirenzeMuse',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/humanity/'Firenze Muse'.vue")
  },

  {
    path: '/works/humanity/untitled-3',
    name: 'Untitled3',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/humanity/'Untitled 3'.vue")
  },

  {
    path: '/works/humanity/woman-on-a-train',
    name: 'WomanOnATrain',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/humanity/'Woman on a Train'.vue")
  },

  {
    path: '/works/humanity/untitled-4',
    name: 'Untitled4',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/humanity/'Untitled 4'.vue")
  },

  {
    path: '/works/humanity/fallen',
    name: 'Fallen',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/humanity/'Fallen'.vue")
  },

  {
    path: '/works/humanity/ponder',
    name: 'Ponder',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/humanity/'Ponder'.vue")
  },

  {
    path: '/works/humanity/village',
    name: 'Village',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/humanity/'Village'.vue")
  },

  {
    path: '/works/humanity/dreamers',
    name: 'Dreamers',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/humanity/'Dreamers'.vue")
  },

  {
    path: '/works/humanity/2018',
    name: '2018',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/humanity/'2018'.vue")
  },

  {
    path: '/works/humanity/agony',
    name: 'Agony',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/humanity/'Agony'.vue")
  },

  {
    path: '/works/humanity/muse-series-no-3',
    name: 'MuseSeries3',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/humanity/'Muse Series No.3'.vue")
  },

  {
    path: '/works/humanity/muse-series-no-2',
    name: 'MuseSeries2',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/humanity/'Muse Series No.2'.vue")
  },

  {
    path: '/works/humanity/muse',
    name: 'Muse',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/humanity/'Muse'.vue")
  },

  {
    path: '/works/humanity/rival',
    name: 'Rival',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/humanity/'Rival'.vue")
  },

  {
    path: '/works/humanity/little-lilac-girl',
    name: 'LittleLilacGirl',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/humanity/'Little Lilac Girl'.vue")
  },

  {
    path: '/works/humanity/broken',
    name: 'Broken',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/humanity/'Broken'.vue")
  },

  {
    path: '/works/humanity/untitled-5',
    name: 'Untitled5',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/humanity/'Untitled 5'.vue")
  },

  {
    path: '/works/humanity/interrupted-dream',
    name: 'InterruptedDream',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/humanity/'Interrupted Dream'.vue")
  },

  {
    path: '/works/humanity/untitled-6',
    name: 'Untitled6',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/humanity/'Untitled 6'.vue")
  },

  {
    path: '/works/humanity/mini-vision',
    name: 'MiniVisión',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/humanity/'Mini Vision'.vue")
  },

  {
    path: '/works/humanity/son',
    name: 'Son',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/humanity/'Son.'.vue")
  },

  {
    path: '/works/epiphany',
    name: 'Epiphany',
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/epiphany/Epiphany.vue")
  },

  {
    path: '/works/epiphany/:work',
    name: 'EpiphanyWork',
    props: true,
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/epiphany/Work.vue")
  },

  {
    path: '/works/redemption',
    name: 'Redemption',
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/redemption/Redemption.vue")
  },

  {
    path: '/works/redemption/:work',
    name: 'RedemptionWork',
    props: true,
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/redemption/Work.vue")
  },

  {
    path: '/works/destination',
    name: 'Redemption',
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/destination/Destination.vue")
  },

  {
    path: '/works/destination/:work',
    name: 'DestinationWork',
    props: true,
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/destination/Work.vue")
  },

  {
    path: '/works/feast',
    name: 'Feast',
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/feast/Feast.vue")
  },

  {
    path: '/works/feast/:work',
    name: 'FeastWork',
    props: true,
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/feast/Work.vue")
  },

  {
    path: '/works/awakening',
    name: 'Awakening',
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/awakening/Awakening.vue")
  },

  {
    path: '/works/awakening/:work',
    name: 'AwakeningWork',
    props: true,
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/awakening/Work.vue")
  },

  {
    path: '/works/blue',
    name: 'Blue',
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/blue/Blue.vue")
  },

  {
    path: '/works/blue/:work',
    name: 'BlueWork',
    props: true,
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/blue/Work.vue")
  },

  {
    path: '/works/flourish',
    name: 'Flourish',
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/flourish/Flourish.vue")
  },

  {
    path: '/works/flourish/:work',
    name: 'FlourishWork',
    props: true,
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/flourish/Work.vue")
  },

  {
    path: '/works/embolden',
    name: 'Embolden',
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/embolden/Embolden.vue")
  },

  {
    path: '/works/embolden/:work',
    name: 'EmboldenWork',
    props: true,
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/embolden/Work.vue")
  },

  {
    path: '/works/excavation',
    name: 'Excavation',
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/excavation/Excavation.vue")
  },

  {
    path: '/works/excavation/:work',
    name: 'ExcavationWork',
    props: true,
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/excavation/Work.vue")
  },

  {
    path: '/works/fields',
    name: 'Fields',
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/fields/Fields.vue")
  },

  {
    path: '/works/fields/:work',
    name: 'FieldsWork',
    props: true,
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/fields/Work.vue")
  },

  {
    path: '/works/disposition',
    name: 'Disposition',
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/disposition/Disposition.vue")
  },

  {
    path: '/works/disposition/:work',
    name: 'DispositionWork',
    props: true,
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/disposition/Work.vue")
  },

  {
    path: '/works/compassion',
    name: 'Compassion',
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/compassion/Compassion.vue")
  },

  {
    path: '/works/compassion/:work',
    name: 'CompassionWork',
    props: true,
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/compassion/Work.vue")
  },

  {
    path: '/works/confrontation',
    name: 'Confrontation',
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/confrontation/Confrontation.vue")
  },

  {
    path: '/works/confrontation/:work',
    name: 'ConfrontationWork',
    props: true,
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/confrontation/Work.vue")
  },

  {
    path: '/works/bloom',
    name: 'Bloom',
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/bloom/Bloom.vue")
  },

  {
    path: '/works/bloom/:work',
    name: 'BloomWork',
    props: true,
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/bloom/Work.vue")
  },

  {
    path: '/works/celebrate',
    name: 'Celebrate',
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/celebrate/Celebrate.vue")
  },

  {
    path: '/works/celebrate/:work',
    name: 'CelebrateWork',
    props: true,
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/celebrate/Work.vue")
  },

  {
    path: '/works/coexistence',
    name: 'Coexistence',
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/coexistence/Coexistence.vue")
  },

  {
    path: '/works/coexistence/:work',
    name: 'CoexistenceWork',
    props: true,
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/coexistence/Work.vue")
  },

  {
    path: '/works/corrosion',
    name: 'Corrosion',
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/corrosion/Corrosion.vue")
  },

  {
    path: '/works/corrosion/:work',
    name: 'CorrosionWork',
    props: true,
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/corrosion/Work.vue")
  },

  {
    path: '/works/flurry',
    name: 'Flurry',
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/flurry/Flurry.vue")
  },

  {
    path: '/works/flurry/:work',
    name: 'FlurryWork',
    props: true,
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/flurry/Work.vue")
  },

  {
    path: '/works/others',
    name: 'Others',
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/others/Others.vue")
  },

  {
    path: '/works/others/:work',
    name: 'OtherWork',
    props: true,
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/others/Work.vue")
  },

  {
    path: '/works/gathering',
    name: 'Gathering',
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/gathering/Gathering.vue")
  },

  {
    path: '/works/gathering/:work',
    name: 'GatheringWork',
    props: true,
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/gathering/Work.vue")
  },

  {
    path: '/works/horizon',
    name: 'Horizon',
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/horizon/Horizon.vue")
  },

  {
    path: '/works/horizon/:work',
    name: 'HorizonWork',
    props: true,
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/horizon/Work.vue")
  },

  {
    path: '/works/transmogrification',
    name: 'Transmogrification',
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/transmogrification/Transmogrification.vue")
  },

  {
    path: '/works/transmogrification/:work',
    name: 'TransmogrificationWork',
    props: true,
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/transmogrification/Work.vue")
  },
  {
    path: '/works/recentArtworks',
    name: 'recentArtworks',
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/recentArtworks/recentArtworks.vue")
  },

  {
    path: '/works/recentArtworks/:work',
    name: 'recentArtworksWork',
    props: true,
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/recentArtworks/Work.vue")
  },
  {
    path: '/works/artworks2024',
    name: 'artworks2024',
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/artworks2024/Artworks2024.vue")
  },

  {
    path: '/works/artworks2024/:work',
    name: 'artworks2024Work',
    props: true,
    component: () => import(/* webpackChunkName: "asset-agreement" */ "../views/works/artworks2024/Work.vue")
  },
]

const router = new VueRouter({
  routes
})

export default router
