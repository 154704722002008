<template>
  <div>
    <div class="home">
      <div class="justify-content-between nav">
        <b-nav class="justify-content-start" style="width:33%;">
          <b-nav-item @click="press">Press</b-nav-item>
          <b-nav-item class="active">Exhibits</b-nav-item>
        </b-nav>
        <div style="width:33%;">
          <img alt="Vue logo" src="../assets/logo.png">
          <div class="title mt-5"><h3>Exhibits</h3></div>
        </div>
        <b-nav class="justify-content-end" style="width:33%;">
          <b-nav-item @click="about">About</b-nav-item>
          <b-nav-item @click="works">Works</b-nav-item>
          <b-nav-item @click="contact">Contact</b-nav-item>
        </b-nav>
      </div>
      <div class="news-wrapper mx-auto mt-5">
        <div class="text-center">
          <h1>2024</h1>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:22em;">
            <img src="../assets/Exhibits/babae.jpeg" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">2024: BABAE</h3>

            <h5 class="mt-3">29 Kapitolyo Art Space</h5>

          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:22em;">
            <img src="../assets/Exhibits/allure.jpeg" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">March 17 - April 4, 2024: ALLURE where elegance meets creativity</h3>

            <h5 class="mt-3">The Grey Space | P. Guevarra St 331, San Juan, Philippines</h5>

           
          </div>
        </div>

        <div class="text-center mt-5">
          <h1>2023</h1>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:22em;">
            <img src="../assets/Exhibits/rossosept.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">November 13 - December 1, 2023: L'idea successiva</h3>

            <h5 class="mt-3">Rossocinabro | Via Raffaele Cadorna, 28 Roma</h5>

            <button class="btn btn-read-more mt-4" @click="readMore('https://www.rossocinabro.com/exhibitions/exhibitions_2023/246_L_Idea_Successiva.htm')">VIEW DETAILS</button>
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:22em;">
            <img src="../assets/Exhibits/transitions.jpg" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">October 2023: Transitions</h3>

            <h5 class="mt-3">Leon Gallery | Makati City, Metro Manila Philippines</h5>

            <button class="btn btn-read-more mt-4" @click="readMore('https://leon-gallery.com/')">VIEW DETAILS</button>
          </div>
        </div>
      
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:22em;">
            <img src="../assets/Exhibits/arteborgofrag.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">March 8 - 22, 2023 - April 2, 2023: Fragility Unveiled</h3>

            <h5 class="mt-3">Rossocinabro | Via Raffaele Cadorna, 28 Roma</h5>

            <button class="btn btn-read-more mt-4" @click="readMore('https://www.arteborgo.it/fragility-unveiled')">VIEW DETAILS</button>
          </div>
        </div>
        <div class="text-center">
          <h1>2022</h1>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:22em;">
            <img src="../assets/Exhibits/rossocinabrodec.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">December 16, 2022 - January 6, 2023: Lo Spazio Sacro - 2a ed.</h3>

            <h5 class="mt-3">Rossocinabro | Via Raffaele Cadorna, 28 Roma</h5>

            <button class="btn btn-read-more mt-4" @click="readMore('https://www.rossocinabro.com/exhibitions/exhibitions_2022/234_Lo_Spazio_Sacro.htm')">VIEW DETAILS</button>
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mb-5" style="width:100%;">
          <div class="news-img" style="width:22em;">
            <img src="../assets/Exhibits/Spatial.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">November 12 - December 4: Spatial Spotlight II</h3>
            <h5 class="mt-3">Altro Mondo Creative Space</h5>
            <h5 class="mt-3">1159 Chino Roces Avenue, Barangay San Antonio, </h5>
            <h5 class="mt-3">Altro Mondo Creative Space</h5>
          </div>
        </div>
        <div class="news mx-auto d-flex flex-wrap justify-content-around mb-5" style="width:100%;">
          <div class="news-img" style="width:22em;">
            <img src="../assets/Exhibits/facets.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">November 12 - December 4: Spatial Spotlight II</h3>
            <h5 class="mt-3">Altro Mondo Creative Space</h5>
            <h5 class="mt-3">1159 Chino Roces Avenue, Barangay San Antonio, </h5>
            <h5 class="mt-3">Altro Mondo Creative Space</h5>
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mb-5" style="width:100%;">
          <div class="news-img" style="width:22em;">
            <img src="../assets/Exhibits/reinventing ordinary.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">September 12 - 28: REINVENTING ORDINARY</h3>
            <h5 class="mt-3">Exhibition date: 12th - 28th September 2022</h5>
            <h5 class="mt-3">Location: Rome 00187, Via Raffaele Cadorna 28</h5>
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mb-5" style="width:100%;">
          <div class="news-img" style="width:22em;">
            <img src="../assets/Exhibits/enchanted earth.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">September 2 - 22: Enchanted Earth and Other Worlds</h3>
            <h5 class="mt-3">Agora Gallery</h5>
            <h5 class="mt-3">Thursday, September 8, 2022 6-8 PM Reception</h5>
            <h5 class="mt-3">Location: 530 West 25th Street, New York</h5>
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mb-5" style="width:100%;">
          <div class="news-img" style="width:22em;">
            <img src="../assets/Exhibits/nuovi mondi.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">July 15 - August 31: NUOVI MONDI</h3>
            <h5 class="mt-3">Location: Via Raffaele Cadorna, 28, Rome, </h5>
            <h5 class="mt-3">Metropolitan City of Rome, Italy</h5>
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mb-5" style="width:100%;">
          <div class="news-img" style="width:22em;">
            <img src="../assets/Exhibits/Asset 40.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">May 21 - June 3: Sublimina</h3>
            <h5 class="mt-3">Museo Diocesano San Mattero - Largo Plebiscito 12 - Salerno</h5>

            <button class="btn btn-read-more mt-4" @click="readMore('https://arteborgo.it/dal-21-maggio-al-3-giugno-2022-sublimina')">VIEW DETAILS</button>
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:22em;">
            <img src="../assets/Exhibits/Asset 39.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">April 30 - June 15: Critic’s Choice!</h3>

            <h5 class="mt-3">LRI Design Plaza in cooperation with Galerya Amalia</h5>

            <h5 class="mt-3">LRI Design Plaza, Nicanor Garcia, Makati</h5>

            <button class="btn btn-read-more mt-4" @click="readMore('https://lridesignplaza.com/')">VIEW DETAILS</button>
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:22em;">
            <img src="../assets/Exhibits/Asset 38.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">April 22: Earthly Paradise</h3>

            <h5 class="mt-3">Altro Mondo Creative Space</h5>

            <h5 class="mt-3">1159 Chino Roces Ave., San Antonio Village, Makati City</h5>
          </div>
        </div>
        <hr>
        <div class="text-center mt-5">
          <h1>2021</h1>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:22em;">
            <img src="../assets/Exhibits/Asset 37.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">October 25 - 30: Right Here Right Now</h3>

            <h5 class="mt-3">Rossocinabro | Via Raffaele Cadorna, 28 Roma</h5>

            <button class="btn btn-read-more mt-4" @click="readMore('https://www.rossocinabro.com/exhibitions/exhibitions_2021/216_right_here_right_now.htm')">VIEW DETAILS</button>
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:22em;">
            <img src="../assets/Exhibits/Asset 36.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">October 1 - 15: Origins</h3>

            <h5 class="mt-3">An Online Exhibit</h5>
            <h5 class="mt-3">Curated by David Allen Gozon</h5>
            <h5 class="mt-3">The Grey Space | P. Guevarra St 331, San Juan, Philippines</h5>
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:22em;">
            <img src="../assets/Exhibits/Asset 35.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">September 20 - October 11: Free to Imagine, Free to Choose</h3>

            <h5 class="mt-3">Via Raffaele Cadorna 28, Rome 00187</h5>

            <button class="btn btn-read-more mt-4" @click="readMore('https://www.rossocinabro.com/exhibitions/exhibitions_2021/215_free_to_imagine_free_to_choose.htm')">VIEW DETAILS</button>
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:22em;">
            <img src="../assets/Exhibits/Asset 34.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">July 19 - August 30: On Life</h3>

            <h5 class="mt-3">Rossocinabro</h5>
            <h5 class="mt-3">Via Raffaele Cadorna 28, Rome</h5>

            <button class="btn btn-read-more mt-4" @click="readMore('https://www.rossocinabro.com/exhibitions/exhibitions_2021/214_on_life.htm')">VIEW DETAILS</button>
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:22em;">
            <img src="../assets/Exhibits/Asset 33.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">June 23 - July 13: Here We Are</h3>

            <h5 class="mt-3">Rossocinabro</h5>
            <h5 class="mt-3">Via Raffaele Cadorna 28, Rome</h5>
            <h5 class="mt-3">Monday - Friday 11:00am - 5:00pm</h5>
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:22em;">
            <img src="../assets/Exhibits/Asset 32.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">June 17 - 29: Inifinity Art</h3>

            <h5 class="mt-3">Ateneo de Madrid</h5>
            <h5 class="mt-3">Opening date: June 17 | 6PM</h5>

            <button class="btn btn-read-more mt-4" @click="readMore('https://old.ateneodemadrid.com/Agenda/Exposiciones/Infinity-Art')">VIEW DETAILS</button>
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:22em;">
            <img src="../assets/Exhibits/Asset 31.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">June 10 - 22: Incontemporanea</h3>

            <h5 class="mt-3">Via delle Terme di Traiano 13 - Roma</h5>
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:22em;">
            <img src="../assets/Exhibits/Asset 30.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">May 28 - June 11: ARTISTS TOMORROW</h3>

            <h5 class="mt-3">Rossocinabro | Via Raffaele Cadorna, 28 Roma</h5>
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:22em;">
            <img src="../assets/Exhibits/Asset 29.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">May 20 - 23: Affordable Art Fair</h3>

            <h5 class="mt-3">Agora Gallery</h5>
            <h5 class="mt-3">Metropolitan Pavilion, 125 West 18th Street, NYC</h5>
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:22em;">
            <img src="../assets/Exhibits/Asset 28.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">May 6- 15: MIND</h3>

            <h5 class="mt-3">ART FAIR Philippines: Galerie Roberto</h5>
            <h5 class="mt-3">May 6 - 15, 2021</h5>
            <h5 class="mt-3">Art Elaan Gallery</h5>
            <h5 class="mt-3">2nd floor, Filipino Village, Ayala Malls, Manila Bay, </h5>
            <h5 class="mt-3">Macapagal Blvd. cor., Aseana Ave., Paranaque City, Manila Philippines</h5>
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:22em;">
            <img src="../assets/Exhibits/Asset 27.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">March 20 - April 11: Of Art and Her Stories</h3>

            <h5 class="mt-3">Altro Mondo Creative Space</h5>
            <h5 class="mt-3">1159 Chino Roces Ave., San Antonio Village, Makati City</h5>
          </div>
        </div>
        <div class="news mx-auto mb-5" style="width:100%;">
          <div class="news-img" style="width:100%">
            <img src="../assets/Exhibits/Asset 26.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:35em;">
            <h3 style="font-weight:bold;">March 12 - 31: Third Solo Exhibit</h3>

            <h5 class="mt-3">Art Elaan presents: Sal Ponce-Enrile: “Transformation”</h5>
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:22em;">
            <img src="../assets/Exhibits/Asset 25.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">March 15 - April 2: Nothing Really Matters</h3>

            <h5 class="mt-3">Rossocinabro | Via Raffaele Cadorna, 28 Roma</h5>
            <h5 class="mt-3">#giornatadelcontemporaneo</h5>
            <button class="btn btn-read-more mt-4" @click="readMore('https://www.rossocinabro.com/exhibitions/exhibitions_2021/208_nothing_really_matters.htm')">VIEW DETAILS</button>
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:22em;">
            <img src="../assets/Exhibits/artistname.jpeg" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">February 1 - 15: ArtistName</h3>

            <h5 class="mt-3">An Online Exhibition</h5>
            <h5 class="mt-3">The Grey Space | Manila</h5>
            
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:22em;">
            <img src="../assets/Exhibits/supernova.jpg" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">January 29 - February 12: Supernova </h3>

            <h5 class="mt-3">Location: Molito, Alabang, Muntinlupa City, Manila</h5>
            
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:22em;">
            <img src="../assets/Exhibits/change.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">January 25 - February 19: In Times of Change</h3>

            <h5 class="mt-3">Rossocinabro Gallery</h5>
            <h5 class="mt-3">Contemporary Art in Rome</h5>
            <h5 class="mt-3">#giornatadelcontemporaneo</h5>
            <button class="btn btn-read-more mt-4" @click="readMore('https://www.rossocinabro.com/exhibitions/exhibitions_2021/205_in_times_of_change.htm')">VIEW DETAILS</button>
          </div>
        </div>
        <hr>
        <div class="text-center mt-5">
          <h1>2020</h1>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:22em;">
            <img src="../assets/Exhibits/realities.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">December 21, 2020 - January 10, 2021: Realities in the making</h3>

            <h5 class="mt-3">AMACI Association of Italian Contemporary Art Museums</h5>
            <h5 class="mt-3">#giornatadelcontemporaneo</h5>
            <button class="btn btn-read-more mt-4" @click="readMore('https://www.rossocinabro.com/exhibitions/exhibitions_2020/204_realities_in_the_making.htm')">VIEW DETAILS</button>
            
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:22em;">
            <img src="../assets/Exhibits/volta.jpg" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">December 11 - January 11: VOLTA</h3>

            <h5 class="mt-3">AMACI Association of Italian Contemporary Art Museums</h5>
            <h5 class="mt-3">Galeria Azur Madrid</h5>
            
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:22em;">
            <img src="../assets/Exhibits/plural.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">December 5 - 11: A Plural Identity</h3>

            <h5 class="mt-3">Giornata del Contemporaneo 2020</h5>
            <h5 class="mt-3">#giornatadelcontemporaneo | Rossocinabro </h5>
            <button class="btn btn-read-more mt-4" @click="readMore('https://www.youtube.com/watch?v=DILPavxgU-U')">VIEW DETAILS</button>
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:22em;">
            <img src="../assets/Exhibits/withoutborders.jpg" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">October 24-November 05: Without Borders</h3>

            <h5 class="mt-3">Arte Borgo Gallery | Rome</h5>
            
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:22em;">
            <img src="../assets/Exhibits/permanent.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">October 26 - 31: Permanent is Nowhere</h3>

            <h5 class="mt-3">Location: Rome 00187, Via Raffaele Cadorna 28</h5>
            <button class="btn btn-read-more mt-4" @click="readMore('https://www.rossocinabro.com/exhibitions/exhibitions_2020/202_permanent_is_nowhere.htm')">VIEW DETAILS</button>
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:22em;">
            <img src="../assets/Exhibits/moreismore.jpg" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">September 10 - October 8: More is More</h3>

            <h5 class="mt-3">Monday - Friday | 11am - 7pm</h5>
            <h5 class="mt-3">Rossocinabro | Rome</h5>
            <button class="btn btn-read-more mt-4" @click="readMore('https://www.rossocinabro.com/exhibitions/exhibitions_2020/201_more_is_more.htm')">VIEW DETAILS</button>
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img" style="width:22em;">
            <img src="../assets/Exhibits/uninterrupted.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">September 3 - October 4: UNINTERRUPTED</h3>

            <h5 class="mt-3">ALTRO MONDO | Philippines</h5>
            <button class="btn btn-read-more mt-4" @click="readMore('https://artspaces.kunstmatrix.com/en/exhibition/2286257/uninterrupted')">VIEW DETAILS</button>
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img my-auto" style="width:22em;">
            <img src="../assets/Exhibits/chromatic.jpg" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">September 5: Chromatic Harmonies</h3>

            <h5 class="mt-3">Museo Crocetti - Via Cassia, 492 - 00189 Roma</h5>
            <h5 class="mt-3">September 5, 2020 | 5 PM</h5>
            
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img my-auto" style="width:22em;">
            <img src="../assets/Exhibits/genesis.jpg" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">September 4: Genesis</h3>

            <h5 class="mt-3">Museo Bellini: Lungario Soderini 5 Firenze</h5>
            <h5 class="mt-3">September 4, 2020 | 5 PM</h5>
            
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img my-auto" style="width:22em;">
            <img src="../assets/Exhibits/fervid.jpeg" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">August 16: FERVID</h3>

            <h5 class="mt-3">Online Group Exhibition</h5>
            <h5 class="mt-3">The Grey Space | Manila</h5>
            <h5 class="mt-3">August 16, 2020</h5>
            
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img my-auto" style="width:22em;">
            <img src="../assets/Exhibits/stories.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">August 3-30: Stories From Before</h3>

            <h5 class="mt-3">Exhibition date: 3rd - 30th August 2020</h5>
            <h5 class="mt-3">Monday - Friday | 11:00 - 17:00</h5>
            <h5 class="mt-3">Location: Rome 00187, Via Raffaele Cadorna 28</h5>
            <button class="btn btn-read-more mt-4" @click="readMore('https://www.rossocinabro.com/exhibitions/exhibitions_2020/200_stories_from_before.htm')">VIEW DETAILS</button>
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img my-auto" style="width:22em;">
            <img src="../assets/Exhibits/collections.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">May 18 - July 31: Art Collections</h3>

            <h5 class="mt-3">Rossocinabro: In Gallery</h5>
            <h5 class="mt-3">Location: Rome 00187, Via Raffaele Cadorna 28</h5>
            <h5 class="mt-3">Monday - Friday 11:00 am - 5:00 pm temporary opening hours</h5>
            <button class="btn btn-read-more mt-4" @click="readMore('https://www.rossocinabro.com/exhibitions/exhibitions_2020/198_art_collections_preview.htm')">VIEW DETAILS</button>
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img my-auto" style="width:22em;">
            <img src="../assets/Exhibits/pandaemonia.jpg" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">April 21: Convergence: Art in the Age of Pandaemonia</h3>

            <h5 class="mt-3">Online International Exhibition</h5>
            
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img my-auto" style="width:22em;">
            <img src="../assets/Exhibits/coexistence.jpg" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">April 10 - 30: Co-Existence 7</h3>

            <h5 class="mt-3">Online Exhibition: view “ART SELECTED”</h5>
            <button class="btn btn-read-more mt-4" @click="readMore('https://www.rossocinabro.com/exhibitions/exhibitions_2020/195_co-existence_7.htm')">VIEW DETAILS</button>
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img my-auto" style="width:22em;">
            <img src="../assets/Exhibits/pandemia.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">April 9, 2020: Convergence in The Age of Pandemia</h3>

            <h5 class="mt-3">Dreams and Divinities & AOI Europe Facebook Page </h5>
            <h5 class="mt-3">www.dreamsanddivinities.com </h5>
            <button class="btn btn-read-more mt-4" @click="readMore('https://www.rossocinabro.com/exhibitions/exhibitions_2020/198_art_collections_preview.htm')">VIEW DETAILS</button>
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img my-auto" style="width:22em;">
            <img src="../assets/Exhibits/sis.jpeg" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">March 4-25: Solitude, Introspection, Surrealism</h3>

            <h5 class="mt-3">Agora Gallery </h5>
            
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img my-auto" style="width:22em;">
            <img src="../assets/Exhibits/likethis.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">March 7 - 31: Like This and Like That</h3>

            <h5 class="mt-3">Meeting with artists: 7th March from 6 to 8 PM</h5>
            <h5 class="mt-3">Location: Rome 00187, Via Raffaele Cadorna 28</h5>
            <button class="btn btn-read-more mt-4" @click="readMore('https://www.rossocinabro.com/exhibitions/exhibitions_2020/194_like_this_and_like_that.htm')">VIEW DETAILS</button>
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img my-auto" style="width:22em;">
            <img src="../assets/Exhibits/elsewhere.jpeg" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">February 7-29: On Earth and Elsewhere</h3>

            <h5 class="mt-3">Exhibition date: 7th - 29th February 2020</h5>
            <h5 class="mt-3">Location: Rome 00187, Via Raffaele Cadorna 28</h5>
            <button class="btn btn-read-more mt-4" @click="readMore('https://www.rossocinabro.com/exhibitions/exhibitions_2020/193_on_earth_and_elsewhere.htm')">VIEW DETAILS</button>
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img my-auto" style="width:22em;">
            <img src="../assets/Exhibits/contemporary.jpg" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">January 31-February 12: Contemporary Sight</h3>

            <h5 class="mt-3">Associazione Culturale “Arte Borgo”</h5>
            <h5 class="mt-3">Borgo Vittorio, 25 00193 Roma</h5>
            
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img my-auto" style="width:22em;">
            <img src="../assets/Exhibits/showcase.jpg" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">January 13-31: Showcase</h3>

            <h5 class="mt-3">Rome 00187, Via Raffaele Cadorna 28</h5>
            
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img my-auto" style="width:22em;">
            <img src="../assets/Exhibits/alchemy.jpg" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">January 14-February 2: Alchemy</h3>

            <h5 class="mt-3">3rd Level Greenbelt 5, Legazpi Street, Legazpi Village </h5>
            <h5 class="mt-3">Makati City</h5>
            <button class="btn btn-read-more mt-4" @click="readMore('https://www.facebook.com/ArteBettinaGallery/posts/576564736253636?__tn__=K-R')">VIEW DETAILS</button>
          </div>
        </div>
        <hr>
        <div class="text-center mt-5">
          <h1>2019</h1>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img my-auto" style="width:22em;">
            <img src="../assets/Exhibits/ideas.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">December 10 - 31: Ideas are Forms of Life</h3>

            <h5 class="mt-3">Location: Rome 00187, Via Raffaele Cadorna 28</h5>
            <button class="btn btn-read-more mt-4" @click="readMore('https://www.rossocinabro.com/exhibitions/exhibitions_2019/190_ideas_are_forms_of_life.htm')">VIEW DETAILS</button>
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img my-auto" style="width:22em;">
            <img src="../assets/Exhibits/reddot.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">December 4-8: Red Dot Miami</h3>

            <h5 class="mt-3">Mana Wynwood Convention Center, 318 NW 23rd St, Miami, Florida</h5>
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img my-auto" style="width:22em;">
            <img src="../assets/Exhibits/rossocinabro.jpg" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">November 12-30: Be Rossocinabro</h3>

            <h5 class="mt-3">Rome 00187, Via Raffaele Cadorna 28</h5>
            <button class="btn btn-read-more mt-4" @click="readMore('https://www.rossocinabro.com/exhibitions/exhibitions_2019/189_be_rossocinabro.htm')">VIEW DETAILS</button>
            
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img my-auto" style="width:22em;">
            <img src="../assets/Exhibits/imaginibus.jpg" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">November 12-17: Imaginibus</h3>

            <h5 class="mt-3">Antico Oratorio Della Passione Piazza Sant'Ambrogio, </h5>
            <h5 class="mt-3">15, 20123 Milano MI, Italy</h5>
            <button class="btn btn-read-more mt-4" @click="readMore('https://www.facebook.com/events/1416152601893970/1416152608560636/?event_time_id=1416152605227303')">VIEW DETAILS</button>

          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img my-auto" style="width:22em;">
            <img src="../assets/Exhibits/shibuya.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">November 11-17:Shibuya Station Exhibition 2nd Edition</h3>

            <h5 class="mt-3">Tokyo, Japan</h5>

            
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img my-auto" style="width:22em;">
            <img src="../assets/Exhibits/fusing.jpg" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">October 12: Fusing Complementary Opposites</h3>

            <h5 class="mt-3">Via Raffaele Cadorna, 28, Rome, Metropolitan City of Rome, Italy</h5>
            <h5 class="mt-3">Rome, Italy</h5>
            
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img my-auto" style="width:22em;">
            <img src="../assets/Exhibits/collection.jpeg" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">September 14-28: Collection</h3>

            <h5 class="mt-3">Jelmoni Studio Gallery Via Molineria S. Nicolò 8-29  </h5>
            <h5 class="mt-3">121 Piacenza, Italy </h5>
            
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img my-auto" style="width:22em;">
            <img src="../assets/Exhibits/artbox.jpg" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">August 15-19: Art Box Project Swiss Art Expo</h3>

            <h5 class="mt-3">SBB Event Hall Bahnhofpl. 8001 Zürich, Switzerland</h5>
            
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img my-auto" style="width:22em;">
            <img src="../assets/Exhibits/mentalspace.jpeg" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">August 14-28: Mental Space</h3>

            <h5 class="mt-3">Galerie Stephanie, Inc.</h5>
            <h5 class="mt-3">Unit 4021, 4th Floor, East Wing, Shangri-La Plaza Mall</h5>
            <h5 class="mt-3">EDSA cor. Shaw Boulevard</h5>
            <h5 class="mt-3">Mandaluyong City, Philippines 1552</h5>
            
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img my-auto" style="width:22em;">
            <img src="../assets/Exhibits/mentalspace.jpeg" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">August 14-28: Mental Space</h3>

            <h5 class="mt-3">Galerie Stephanie, Inc.</h5>
            <h5 class="mt-3">Unit 4021, 4th Floor, East Wing, Shangri-La Plaza Mall</h5>
            <h5 class="mt-3">EDSA cor. Shaw Boulevard</h5>
            <h5 class="mt-3">Mandaluyong City, Philippines 1552</h5>
            
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img my-auto" style="width:22em;">
            <img src="../assets/Exhibits/passeig.jpeg" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">July 1-31: Passeig de Gracia Station Barcelona</h3>
            
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img my-auto" style="width:22em;">
            <img src="../assets/Exhibits/refractions.jpg" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">June 22-July 15: Refractions</h3>
            
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img my-auto" style="width:22em;">
            <img src="../assets/Exhibits/artcube.jpg" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">June 15: Art Cube Gallery Solo Exhibit</h3>
            
          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img my-auto" style="width:22em;">
            <img src="../assets/Exhibits/whitespace.jpg" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">May 26: White Space Chelsea New York</h3>
            <button class="btn btn-read-more mt-4" @click="readMore('https://whitespacechelsea.com/')">VIEW DETAILS</button>

          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img my-auto" style="width:22em;">
            <img src="../assets/Exhibits/artexpo.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">April 4-7:Artexpo New York</h3>
            

          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img my-auto" style="width:22em;">
            <img src="../assets/Exhibits/istoryart.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">March 30-April 10: Istoryart: Art Circle Gallery</h3>
            <button class="btn btn-read-more mt-4" @click="readMore('https://www.facebook.com/events/257497408512336/')">VIEW DETAILS</button>


          </div>
        </div>
        <hr>
        <div class="text-center mt-5">
          <h1>2018</h1>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img my-auto" style="width:22em;">
            <img src="../assets/Exhibits/aspace.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">March 11: The Awakening:A-Space Makati</h3>
            

          </div>
        </div>
        <div class="news d-flex flex-wrap justify-content-around mx-auto mt-5" style="width:100%;">
          <div class="news-img my-auto" style="width:22em;">
            <img src="../assets/Exhibits/dragonart.png" alt="" style="width:100%;">
          </div>
          <div class="news-text text-left my-auto" style="width:22em;">
            <h3 style="font-weight:bold;">Dragon Art 2018 Exhibit</h3>
            <button class="btn btn-read-more mt-4" @click="readMore('https://www.facebook.com/events/257497408512336/')">VIEW DETAILS</button>

          </div>
        </div>
      </div>
    </div>
    <div class="footer mt-5">
      <h3 class="text-white">&copy; 2022 SALPONCEENRILE.COM</h3>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
    
  },
  methods: {
    handleSlideClick (dataset) {
      console.log(dataset.index, dataset.name)
    },

    about() {
      this.$router.push('/')
    },

    press() {
      this.$router.push('/press')
    },

    works() {
      this.$router.push('/works')
    },

    contact() {
      this.$router.push('/contact')
    },

    readMore(link) {
      window.open(link)
    }
  }
}
</script>
<style scoped>
.title{
  color: black !important;
  font-weight: bolder !important;
  font-size: 20px;
}
.nav .active{
  color: black !important;
  font-weight: bolder !important;
}
.quote{
  font-family:'Montserrat-SemiBold', sans-serif !important;
  font-size: 50px !important;
  text-align: center !important;
  display: flex !important;
  justify-content: center !important;

}

.footer {
  background-color:black;
  width:100%;
  padding:30px;
  color:#666;
}

.text-left {
  text-align:left !important;
}

.profile p {
  font-family:'Montserrat-Regular' !important;
  color:#666666;
  font-size:20px;
}

.profile h3 {
  font-family:'Montserrat-Bold' !important;
  color:#333;
  font-size:35px;
}

.btn-talk {
  font-family:'Montserrat-Regular' !important;
  padding:20px;
  border-radius:50px;
  border:2px #333 solid;
  width:15em;
  font-size:20px;
}

.btn-talk:hover {
  font-family:'Montserrat-Regular' !important;
  padding:20px;
  border-radius:50px;
  border:2px #333 solid;
  background:#333;
  color:white;
  width:15em;
  font-size:20px;
}

.nav-link {
  color:#a8afb7;
}

.active .nav-link {
  color:#181819;
  font-weight:bold;
}

.home {
  padding:20px 50px;
}

.author {
  font-family:'Montserrat-Light' !important;
  color:#898989;
}

.h5-2 {
  font-family:'Montserrat-SemiBold' !important;
  color:#898989;
}

h5 {
  font-family:'Montserrat-Regular' !important;
  color:#898989;
}

.btn-read-more {
  border:2px #333 solid;
  padding:15px;
  border-radius:0px;
}

.btn-read-more:hover {
  border:2px #333 solid;
  background:#333;
  color:white;
  padding:15px;
  border-radius:0px;
}

.news {
  margin-bottom:20px !important;
}

.news-wrapper {
  width:50%;
}

@media screen and (max-width:800px) {
  .news-wrapper {
    width:100%;
  }
}

@media screen and (max-width:380px) {
  .home {
    padding:20px 30px;
  }
}

</style>
