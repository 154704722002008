<template>
  <div>
    <div class="home">
      <div class="justify-content-between nav">
        <b-nav class="justify-content-start" style="width:33%;">
          <b-nav-item @click="press">Press</b-nav-item>
          <b-nav-item @click="exhibits">Exhibits</b-nav-item>
        </b-nav>
        <div style="width:33%;">
          <img alt="Vue logo" src="../assets/logo.png">
          <div class="title mt-5"><h3>CONTACT US</h3></div>
        </div>
        <b-nav class="justify-content-end" style="width:33%;">
          <b-nav-item @click="about">About</b-nav-item>
          <b-nav-item @click="works">Works</b-nav-item>
          <b-nav-item class="active">Contact</b-nav-item>
        </b-nav>
      </div>
      <div class="d-flex flex-wrap justify-content-center mt-5">
        <div class="form text-left">
          <div class="form-group">
            <label for="exampleInputEmail1">Name:</label>
            <input type="email" class="form-control" id="exampleInputEmail1">
          </div>
          <div class="form-group mt-3">
            <label for="exampleInputEmail1">I am a:</label>
            <input type="email" class="form-control" id="exampleInputEmail1">
          </div>
          <div class="form-group mt-3">
            <label for="exampleInputEmail1">Email:</label>
            <input type="email" class="form-control" id="exampleInputEmail1">
          </div>
          <div class="form-group mt-3">
            <label for="exampleFormControlTextarea1">Message:</label>
            <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
          </div>
          <button class="btn btn-dark mt-3 btn-send">SEND</button>
        </div>
        <div class="notice text-left">
          <h1 class="notice-text">Please just kindly reach out to our team at
          <span class="bold">salponce-enrile@gmail.com</span> or use the form
          above, so we can make proper arrangements. </h1>
        </div>
      </div>
    </div>
    <div class="footer mt-5">
      <h3 class="text-white">&copy; 2022 SALSPONCEENRILE.COM</h3>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
    
  },
  methods: {
    handleSlideClick (dataset) {
      console.log(dataset.index, dataset.name)
    },

    press() {
      this.$router.push('/press')
    },

    exhibits() {
      this.$router.push('/exhibits')
    },

    works() {
      this.$router.push('/works')
    },

    about() {
      this.$router.push('/')
    },
  }
}
</script>
<style scoped>
.title{
  color: black !important;
  font-weight: bolder !important;
  font-size: 20px;
}
.nav .active{
  color: black !important;
  font-weight: bolder !important;
}

.footer {
  background-color:black;
  width:100%;
  padding:30px;
  color:#666;
}

.active .nav-link {
  color:#181819;
  font-weight:bold;
}

.nav-link {
  color:#a8afb7;
}

.home {
  padding:20px 50px;
  height:auto;
  min-height: 80vh;
}

.text-left {
  text-align: left !important;
}

.form {
  width:50em;
  background-color:rgb(234, 234, 234);
  padding:30px 50px;
  border-radius:10px;
}

label {
  font-family: 'Montserrat-Regular';
  font-size:22px;
}

input, textarea {
  border:none;
}

.notice {
  width:40em;
}

.notice-text {
  font-family: 'Montserrat-Regular';
  font-size:25px;
  color:#808080;
}

.bold {
  font-family: 'Montserrat-Bold';
  font-size:25px;
  color:#000;
}

.notice, .form {
  margin:20px;
}

.btn-send {
  width:100%;
  padding:10px;
}

@media screen and (max-width:1024px) {
  .home {
    padding:0px 60px;
    height:auto;
  }
}

@media screen and (max-width:450px) {
  .home {
    padding:20px 30px;
  }
}


</style>
