<template>
  <div>
    <div class="home">
      <div class="justify-content-between nav">
        <b-nav class="justify-content-start" style="width:33%;">
          <b-nav-item @click="press">Press</b-nav-item>
          <b-nav-item @click="exhibits">Exhibits</b-nav-item>
        </b-nav>
        <div style="width:33%;">
          <img alt="Vue logo" src="../assets/logo.png">
          <div class="title mt-5"><h3>About</h3></div>
        </div>
        <b-nav class="justify-content-end" style="width:33%;">
          <b-nav-item class="active">About</b-nav-item>
          <b-nav-item @click="works">Works</b-nav-item>
          <b-nav-item @click="contact">Contact</b-nav-item>
        </b-nav>
      </div>

      <div class="mx-auto mt-5 top-wrapper" style="width:70%;">
        <h1 class="quote">"Art comforts the senses and purges them from unwanted tensions."</h1>
        <img src="../assets/About/Asset 3.png" style="width:75%;" class="sal-img"/>
      </div>
      
      <div style="width:70%;" class="mx-auto mt-5 text-wrapper">
        <div class="text-left profile">
          <h3 class="mt-4 text-center" style="font-weight:bold;">Sal Ponce-Enrile</h3>
          <p class="mt-2 text-left">When viewing the art of painter Sal Ponce-Enrile, you’d be forgiven for forgetting she was a public
          figure who served as a legislator for 6 years in the Philippines. Her art deeply reflects the 17 years of
          passion that once started as a hobby.</p>
          <p class="mt-2">Sal’s artwork depicts controlled chaos. The artist embraces the use of mixed media, acrylics, and
          textures that add character and depth to the uncensored expression of her work. Throughout her
          career, she’s always sought out opportunities to enhance the lives of others through her art. She now
          plans to resume her advocacy in health and education by creating a foundation that will benefit from
          all of her future art exhibits.</p>
          <p class="mt-2">Sal’s early studies in criminology, psychology, and business allowed her to observe and study people’s
          behavior and true nature. Yet it was her political background that gave her the opportunity to witness
          the real challenges people in extreme poverty are facing daily. It’s these genuinely kind and generous
          people she has met that’s given her a unique way to see the world. Today, reflecting on those
          moments remains her inspiration.</p>
          <p class="mt-2">Sal now uses her art as a platform to voice the complexities of her experiences. From the colors, to the
          composition, her approach and curiosity to mix one style or technique have resulted in her unique
          identity, leaving her art with an intrigued and mysterious feel.</p>
          <p class="mt-2">Sal is an artist-in-residence at <strong>Rossocinabro Gallery</strong>, Rome.</p>

          <div class="text-center">
            <button class="btn btn-talk mt-4 mx-auto" @click="contact">Let's Talk</button>
          </div>
        </div>
      </div>

      <div class="text-center mt-5">
        <h2 class="mb-4" style="font-weight:bold;">Recent artworks</h2>
        <carousel autoplay loop autoplayTimeout="5000" perPage="3" class="lg-screen-slide">
          <slide data-index="0" data-name="MySlideName" @slideclick="handleSlideClick">
            <img src="../assets/Artworks2024/Candor.jpeg" class="slide-img"/>
          </slide>
          <slide>
            <img src="../assets/Artworks2024/Chances.jpeg" class="slide-img"/>
          </slide>
          <slide>
            <img src="../assets/Artworks2024/Nostalgia.jpeg" class="slide-img"/>
          </slide>
          <slide>
             <img src="../assets/Artworks2024/Paradox.jpeg" class="slide-img"/>
          </slide>
        </carousel>
        <carousel autoplay loop autoplayTimeout="5000" perPage="1" class="sm-screen-slide">
          <slide data-index="0" data-name="MySlideName" @slideclick="handleSlideClick">
            <img src="../assets/Artworks2024/Candor.jpeg" class="slide-img"/>
          </slide>
          <slide>
            <img src="../assets/Artworks2024/Chances.jpeg" class="slide-img"/>
          </slide>
          <slide>
            <img src="../assets/Artworks2024/Nostalgia.jpeg" class="slide-img"/>
          </slide>
          <slide>
            <img src="../assets/Artworks2024/Paradox.jpeg" class="slide-img"/>
          </slide>
        </carousel>
      </div>
    </div>
    <div class="footer mt-5">
      <h3 class="text-white">&copy; 2022 SALPONCEENRILE.COM</h3>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
    
  },
  methods: {
    handleSlideClick (dataset) {
      console.log(dataset.index, dataset.name)
    },

    press() {
      this.$router.push('/press')
    },

    exhibits() {
      this.$router.push('/exhibits')
    },

    works() {
      this.$router.push('/works')
    },

    contact() {
      this.$router.push('/contact')
    },
  }
}
</script>
<style scoped>
.title{
  color: black !important;
  font-weight: bolder !important;
  font-size: 20px;
}
.nav .active{
  color: black !important;
  font-weight: bolder !important;
}
.quote{
  font-family:'Montserrat-SemiBold', sans-serif !important;
  font-size: 50px !important;
  text-align: center !important;
  display: flex !important;
  justify-content: center !important;

}

.footer {
  background-color:black;
  width:100%;
  padding:30px;
  color:#666;
}

.text-left {
  text-align:left !important;
}

.profile p {
  font-family:'Montserrat-Regular' !important;
  color:#666666;
  font-size:20px;
}

.profile h3 {
  font-family:'Montserrat-Bold' !important;
  color:#333;
  font-size:35px;
}

.btn-talk {
  font-family:'Montserrat-Regular' !important;
  padding:20px;
  border-radius:50px;
  border:2px #333 solid;
  width:15em;
  font-size:20px;
}

.btn-talk:hover {
  font-family:'Montserrat-Regular' !important;
  padding:20px;
  border-radius:50px;
  border:2px #333 solid;
  background:#333;
  color:white;
  width:15em;
  font-size:20px;
}

.nav-link {
  color:#a8afb7;
}

.active .nav-link {
  color:#181819;
  font-weight:bold;
}

.home {
  padding:20px 50px;
}

.sm-screen-slide {
  display:none;
}

@media screen and (max-width:1024px) {
  .slide-img {
    width:15em;
  }
}

@media screen and (max-width:800px) {
  .quote {
    font-size:40px !important;
  }

  .slide-img {
    width:12em;
  }
}

@media screen and (max-width:450px) {
  .text-wrapper {
    width:100% !important;
  }

  .slide-img {
    width:10em;
  }

  .sm-screen-slide {
    display:block;
  }

  .lg-screen-slide {
    display:none;
  }

  .sal-img {
    width:100% !important;
  }

  .top-wrapper {
    width:100% !important;
  }

  .quote {
    font-size:30px !important;
  }

  .slide-img {
    width:20em;
  }
}

@media screen and (max-width:380px) {
  .home {
    padding:20px 30px;
  }

  .slide-img {
    width:18em;
  }
}

@media screen and (max-width:325px) {
  .home {
    padding:20px;
  }

  .slide-img {
    width:16em;
  }

  .btn-talk {
    width:10em;
  }
}
</style>
