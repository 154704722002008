import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    works: {
      epiphany: {
        sentiment3: {
          title: 'Sentiment Series: No. 3.',
          description: 'Mixed media on canvas on board.',
          measurements: '4 x 5 ft.',
          route: 'sentiment-series-3',
          image: 'SentimentSeries3.jpg'
        },
        sentiment2: {
          title: 'Sentiment Series: No. 2',
          description: 'Mixed media on canvas',
          measurements: '36 x 28.5 in.',
          route: 'sentiment-series-2',
          image: 'SentimentSeries2.png'
        },
        sentiment1: {
          title: 'Sentiment Series: No. 1.',
          description: 'Mixed media on canvas',
          measurements: '36 x 28.5 in.',
          route: 'sentiment-series-1',
          image: 'SentimentSeries1.png'
        },
        nostalgia: {
          title: 'Nostalgia',
          description: 'Mixed media on canvas',
          measurements: '22 x 18 inches',
          route: 'nostalgia',
          image: 'Nostalgia.png'
        },
        diversion: {
          title: 'Diversion',
          description: '12 x 18 inches',
          measurements: 'Mixed media on canvas',
          route: 'diversion',
          image: 'Diversion.png'
        },
        distraction: {
          title: 'Distraction.',
          description: '36 x 36 in.',
          measurements: 'Acrylic on canvas.',
          route: 'distraction',
          image: 'Distraction.png'
        },
        transience: {
          title: 'Transience.',
          description: '5 x 4 ft.',
          measurements: 'Acrylic on canvas.',
          route: 'transience',
          image: 'Transience.png'
        },
        untitled: {
          title: 'Untitled.',
          description: '30 x 24 in.',
          measurements: 'Acrylic on canvas',
          route: 'untitled',
          image: 'Untitled.png'
        },
        uncertainty: {
          title: 'Uncertainty.',
          description: '36 x 36 in',
          measurements: 'Oil on Canvas',
          route: 'uncertainty',
          image: 'Uncertainty.png'
        },
        untitled2: {
          title: 'Untitled',
          description: '36 x 36 in',
          measurements: 'Acrylic on Canvas',
          route: 'untitled-2',
          image: 'Untitled2.png',
        },
        untitled3: {
          title: 'Untitled.',
          description: '55 x 42 in.',
          measurements: 'Acrylic on canvas.',
          route: 'untitled-3',
          image: 'Untitled3.png',
        },
        delirious: {
          title: 'Delirious',
          description: '36x36 in.',
          measurements: 'Acrylic on Canvas',
          route: 'delirious',
          image: 'Delirious.png',
        },
        retrospect: {
          title: 'Retrospect',
          description: 'Acrylic on Canvas',
          measurements: '60x36 in.',
          route: 'retrospect',
          image: 'Retrospect.png',
        },
        dream: {
          title: 'Dream',
          description: 'Acrylic on Canvas',
          measurements: '36x24 in.',
          route: 'dream',
          image: 'Dream.png',
        },
        perplexed: {
          title: 'Perplexed',
          description: 'Acrylic on Canvas',
          measurements: '36x48 in.',
          route: 'perplexed',
          image: 'Perplexed.png',
        },
        glade: {
          title: 'Glade',
          description: 'Acrylic and Mixed Media on Canvas',
          measurements: '24x24 in.',
          route: 'glade',
          image: 'Glade.png',
        },
      },
      redemption: {
        possibilities2: {
          title: 'Possibilities 2',
          description: 'Mixed media on canvas on ply board',
          measurements: '4 x 3 ft.',
          route: 'possibilities-2',
          image: 'Possibilities2.jpg'
        },
        possibilities1: {
          title: 'Possibilities 1',
          description: 'Mixed media on canvas on ply board',
          measurements: '4 x 3 ft.',
          route: 'possibilities-2',
          image: 'Possibilities1.jpg'
        },
        patience: {
          title: 'Patience',
          description: 'Acrylic and oil on canvas',
          measurements: '',
          route: 'patience',
          image: 'Patience.png'
        },
        hope: {
          title: 'Hope.',
          description: 'Mixed media on canvas.',
          measurements: '18.5 x 12.5 in.',
          route: 'hope',
          image: 'Hope.png'
        },
        untitled: {
          title: 'Untitled.',
          description: 'Oil on canvas.',
          measurements: '36 x 24 in.',
          route: 'untitled',
          image: 'Untitled.png'
        },
        unfinishedBusiness: {
          title: 'Unfinished Business.',
          description: 'Mixed media on canvas.',
          measurements: '36 x 36 in.',
          route: 'unfinished-business',
          image: 'UnfinishedBusiness.png'
        },
        vivo: {
          title: 'Vivo.',
          description: 'Mixed media on canvas.',
          measurements: '4 x 5 ft.',
          route: 'vivo',
          image: 'Vivo.png'
        },
        untitled2: {
          title: 'Untitled.',
          description: 'Mixed media on canvas.',
          measurements: '36 x 24 in.',
          route: 'untitled-2',
          image: 'Untitled2.png'
        },
        recollection: {
          title: 'Recollection.',
          description: 'Mixed media on canvas.',
          measurements: '30 x 24 in.',
          route: 'recollection',
          image: 'Recollection.png'
        },
        distraction: {
          title: 'Distraction.',
          description: 'Mixed media on canvas',
          measurements: '4 x 3 ft.',
          route: 'distraction',
          image: 'Distraction.png'
        },
        offering: {
          title: 'Offering',
          description: 'Mixed media on canvas',
          measurements: '28.5 x 36 in.',
          route: 'offering',
          image: 'Offering.png'
        },
        redemption: {
          title: 'Redemption',
          description: 'Mixed Media on Canvas',
          measurements: '55x42 in.',
          route: 'redemption',
          image: 'Redemption.png'
        },
        redemptionSeries2: {
          title: 'Redemption Series 2',
          description: 'Mixed Media on Canvas on Plyboard',
          measurements: '55x42 in',
          route: 'redemption-series-2',
          image: 'RedemptionSeries2.png'
        },
        redemptionSeries3: {
          title: 'Redemption Series 3',
          description: 'Mixed Media on Canvas on Plyboard',
          measurements: '60 x 48 in',
          route: 'redemption-series-3',
          image: 'RedemptionSeries3.png'
        },
        drama: {
          title: 'Drama',
          description: 'Mixed Media on Canvas',
          measurements: '36x24 in.',
          route: 'drama',
          image: 'Drama.png'
        },
        harvest: {
          title: 'Harvest',
          description: 'Mixed Media on Canvas',
          measurements: '18x18 in.',
          route: 'harvest',
          image: 'Harvest.png'
        },
        deceptionInGold: {
          title: 'Deception in Gold',
          description: 'Mixed Media on Canvas',
          measurements: '36X24 in.',
          route: 'deception-in-gold',
          image: 'DeceptionInGold.png'
        },
        aglow: {
          title: 'Aglow',
          description: 'Mixed Media on Canvas',
          measurements: '36x36 in.',
          route: 'aglow',
          image: 'Aglow.png'
        },
        intense: {
          title: 'Intense',
          description: 'Mixed Media on Canvas',
          measurements: '48x24 in.',
          route: 'intense',
          image: 'Intense.png'
        },
        playful: {
          title: 'Playful',
          description: 'Acrylic on Canvas',
          measurements: '20x16 in.',
          route: 'playful',
          image: 'Playful.png'
        },
        amused: {
          title: 'Amused',
          description: 'Acrylic on Canvas',
          measurements: '18x18 in.',
          route: 'amused',
          image: 'Amused.png'
        },
      },
      destination: {
        haven: {
          title: 'Haven',
          description: 'Acrylic on canvas on board.',
          measurements: '28.5 x 36 in.',
          route: 'haven',
          image: 'Haven.png'
        },
        passage: {
          title: 'Passage.',
          description: 'Mixed media on canvas on board. 2022',
          measurements: '36 x 28.5 in.',
          route: 'passage',
          image: 'Passage.png'
        },
        myParadise: {
          title: 'My Paradise',
          description: 'Mixed media on canvas on board',
          measurements: '3.5 x 4.5 ft.',
          route: 'my-paradise',
          image: 'MyParadise.png'
        },
        destinationNo2: {
          title: 'Destination No. 2',
          description: 'Mixed Media on Canvas',
          measurements: '24 x 36 in.',
          route: 'destination-no-2',
          image: 'DestinationNo2.png'
        },
        destination: {
          title: 'Destination',
          description: 'Mixed Media on Canvas',
          measurements: '36 x 28.5 in',
          route: 'destination',
          image: 'Destination.png'
        },
        sanctuary: {
          title: 'Sanctuary',
          description: 'Mixed Media on Canvas',
          measurements: '36x28.5 in.',
          route: 'sanctuary',
          image: 'Sanctuary.png'
        },
      },
      feast: {
        feast: {
          title: 'Feast',
          description: '',
          measurements: '',
          route: 'feast',
          image: 'Feast.png'
        },
        cheers: {
          title: '“Cheers”.',
          description: 'Acrylic on canvas.',
          measurements: '',
          route: '',
          image: 'Cheers.png'
        },
      },
      awakening: {
        awakening: {
          title: 'Awakening',
          description: 'Mixed Media on Canvas',
          measurements: '60x48 in.',
          route: 'awakening',
          image: 'Awakening.png'
        },
        tempest: {
          title: 'Tempest',
          description: 'Mixed Media on Canvas',
          measurements: '60x48 in.',
          route: 'tempest',
          image: 'Tempest.png'
        },
        gift: {
          title: 'Gift',
          description: 'Mixed Media on Wood Panel',
          measurements: '36x36 in.',
          route: 'gift',
          image: 'Gift.png'
        },
        reach: {
          title: 'Reach',
          description: 'Mixed Media on Wood Panel',
          measurements: '36x36 in.',
          route: 'reach',
          image: 'Reach.png'
        },
        mist: {
          title: 'Mist',
          description: 'Mixed Media on Canvas',
          measurements: '20x16 in.',
          route: 'mist',
          image: 'Mist.png'
        },
        fury: {
          title: 'Fury',
          description: 'Mixed Media on Canvas',
          measurements: '36x24 in.',
          route: 'fury',
          image: 'Fury.png'
        },
        noble: {
          title: 'Noble',
          description: 'Mixed Media on Canvas',
          measurements: '24x24 in.',
          route: 'noble',
          image: 'Noble.png'
        },
        mystique: {
          title: 'Mystique',
          description: 'Mixed Media on Canvas',
          measurements: '24x24 in.',
          route: 'mystique',
          image: 'Mystique.png'
        },
      },
      blue: {
        blue: {
          title: 'Blue',
          description: 'Acrylic on Canvas',
          measurements: '36x28.5 in.',
          route: 'blue',
          image: 'Blue.png'
        },
        blueGreen: {
          title: 'Blue/Green',
          description: 'Acrylic on Canvas',
          measurements: '28.5x36 in.',
          route: 'blue-green',
          image: 'BlueGreen.png'
        },
        blueGreenGold: {
          title: 'Blue/Green/Gold',
          description: 'Acrylic on Canvas',
          measurements: '36x28.5 in.',
          route: 'blue-green-gold',
          image: 'BlueGreenGold.png'
        },
      },
      flourish: {
        flourish1: {
          title: 'Flourish 1 of 4',
          description: 'Acrylic on Yupo paper',
          measurements: '14x11 in',
          route: 'flourish-1',
          image: 'Flourish1.png'
        },
        flourish2: {
          title: 'Flourish 2 of 4',
          description: 'Acrylic on Canvas',
          measurements: '18x18 in',
          route: 'flourish-2',
          image: 'Flourish2.png'
        },
        flourish3: {
          title: 'Flourish 3 of 4',
          description: 'Acrylic on Yupo paper',
          measurements: '14x11 in',
          route: 'flourish-3',
          image: 'Flourish3.png'
        },
        flourish4: {
          title: 'Flourish 4 of 4',
          description: 'Acrylic on Canvas',
          measurements: '24x24 in',
          route: 'flourish-4',
          image: 'Flourish4.png'
        }
      },
      embolden: {
        embolden: {
          title: 'Embolden',
          description: 'Mixed Media on Board',
          measurements: '36x28.5 in.',
          route: 'embolden',
          image: 'Embolden.png'
        },
        embolden2: {
          title: 'Embolden Series 2',
          description: 'Mixed Media on Canvas',
          measurements: '36x28.5 in.',
          route: 'embolden-2',
          image: 'EmboldenSeries2.png'
        },
      },
      excavation: {
        depth: {
          title: 'Depth',
          description: 'Mixed Media on Wood Panel',
          measurements: '36x36 in.',
          route: 'depth',
          image: 'Depth.png'
        },
        embrace: {
          title: 'Embrace',
          description: 'Acrylic on Canvas',
          measurements: '24x24 in.',
          route: 'embrace',
          image: 'Embrace.png'
        },
        conflict: {
          title: 'Conflict',
          description: 'Mixed Media on Canvas',
          measurements: '60x36 in.',
          route: 'conflict',
          image: 'Conflict.png'
        },
        shadow: {
          title: 'Shadow',
          description: 'Mixed Media on Canvas',
          measurements: '60x48 in.',
          route: 'shadow',
          image: 'Shadow.png'
        },
      },
      fields: {
        fieldsOfHope: {
          title: 'Fields of Hope',
          description: 'Mixed Media on Canvas',
          measurements: '24x24 in.',
          route: 'fields-of-hope',
          image: 'FieldsOfHope.png'
        },
        fieldsOfHope2: {
          title: 'Fields of Hope 2',
          description: 'Mixed Media on Canvas',
          measurements: '24x24 in.',
          route: 'fields-of-hope-2',
          image: 'FieldsOfHope2.png'
        },
        caritas: {
          title: 'Caritas',
          description: 'Mixed Media on Canvas',
          measurements: '60x36 in.',
          route: 'caritas',
          image: 'Caritas.png'
        }
      },
      disposition: {
        glimpse: {
          title: 'Glimpse',
          description: 'Mixed Media on Canvas',
          measurements: '36x36 in.',
          route: 'glimpse',
          image: 'Glimpse.png'
        },
        outlook: {
          title: 'Outlook',
          description: 'Mixed Media on Canvas',
          measurements: '60x36 in.',
          route: 'outlook',
          image: 'Outlook.png'
        },
        transformation: {
          title: 'Transformation',
          description: 'Mixed Media on Canvas',
          measurements: '36x24 in.',
          route: 'transformation',
          image: 'Transformation.png'
        },
        genesis: {
          title: 'Genesis',
          description: 'Acrylic on Canvas',
          measurements: '24x24 in.',
          route: 'genesis',
          image: 'Genesis.png'
        },
        atmosphere: {
          title: 'Atmosphere',
          description: 'Acrylic on Canvas',
          measurements: '24x24 in.',
          route: 'atmosphere',
          image: 'Atmosphere.png'
        },
      },
      compassion: {
        compassion: {
          title: 'Compassion',
          description: 'Mixed Media on Canvas',
          measurements: '36x24 in.',
          route: 'compassion',
          image: 'Compassion.png'
        },
        untitled: {
          title: 'Untitled',
          description: 'Mixed Media on Canvas',
          measurements: '36x24 in.',
          route: 'untitled',
          image: 'Untitled.png'
        },
        untitled2: {
          title: 'Untitled',
          description: 'Mixed Media on Canvas',
          measurements: '36x28 in.',
          route: 'untitled-2',
          image: 'Untitled2.png'
        },
      },
      confrontation: {
        moments: {
          title: 'Moments',
          description: 'Mixed Media on Canvas',
          measurements: '24x24 in.',
          route: 'moments',
          image: 'Moments.png'
        },
        morning: {
          title: 'Morning',
          description: 'Acrylic on Canvas',
          measurements: '24x24 in.',
          route: 'morning',
          image: 'Morning.png'
        },
        serene: {
          title: 'Serene',
          description: 'Acrylic on Canvas',
          measurements: '24x36 in.',
          route: 'serene',
          image: 'Serene.png'
        },
      },
      bloom: {
        hope: {
          title: 'Hope',
          description: 'Acrylic on canvas',
          measurements: '18 x 22 in.',
          route: 'hope',
          image: 'Hope.png'
        },
        bloom: {
          title: 'Bloom',
          description: 'Mixed Media on Canvas',
          measurements: '36x36 in.',
          route: 'bloom',
          image: 'Bloom.png'
        },
        bouquet: {
          title: 'Bouquet',
          description: 'Mixed Media on Canvas',
          measurements: '24x24 in.',
          route: 'bouquet',
          image: 'Bouquet.png'
        },
        thePrayer: {
          title: 'The Prayer',
          description: 'Mixed Media on Canvas',
          measurements: '36x36 in.',
          route: 'the-prayer',
          image: 'ThePrayer.png'
        },
        blooms: {
          title: 'Blooms',
          description: 'Mixed Media on Canvas',
          measurements: '24x30 in.',
          route: 'blooms',
          image: 'Blooms.png'
        },
        bloomsSeries2: {
          title: 'Blooms series 2',
          description: 'Mixed Media on Canvas',
          measurements: '24x30 in.',
          route: 'blooms-series-2',
          image: 'BloomsSeries2.png'
        },
        glamour: {
          title: 'Glamour',
          description: 'Mixed Media on Canvas',
          measurements: '28x28 in.',
          route: 'glamour',
          image: 'Glamour.png'
        },
        opulent: {
          title: 'Opulent',
          description: 'Mixed Media on Canvas',
          measurements: '36x36 in.',
          route: 'opulent',
          image: 'Opulent.png'
        },
      },
      celebrate: {
        blessings3: {
          title: 'Blessings Series. No. 3.',
          description: '— SOLD —',
          measurements: '',
          route: 'blessings-series-3',
          image: 'BlessingsSeries3.png'
        },
        blessings2: {
          title: 'Blessings Series. No. 2.',
          description: '— SOLD —',
          measurements: '',
          route: 'blessings-series-2',
          image: 'BlessingsSeries2.png'
        },
        blessings1: {
          title: 'Blessings Series. No. 1.',
          description: '— SOLD —',
          measurements: '',
          route: 'blessings-series-1',
          image: 'BlessingsSeries1.png'
        },
        celebrate: {
          title: 'Celebrate',
          description: 'Acrylic on Canvas',
          measurements: '36x24 in.',
          route: 'celebrate',
          image: 'Celebrate.png'
        },
        celebrate2: {
          title: 'Celebrate Series 2',
          description: 'Acrylic on Canvas',
          measurements: '36x28.5 in.',
          route: 'celebrate-series-2',
          image: 'CelebrateSeries2.png'
        },
      },
      coexistence: {
        cosmic1: {
          title: 'Cosmic series: No. 1',
          description: 'Acrylic on canvas on board',
          measurements: 'Size: 4 x 3 ft.',
          route: 'cosmic-series-1',
          image: 'CosmicSeries1.png'
        },
        cosmic2: {
          title: 'Cosmic series: No. 2',
          description: 'Acrylic on canvas on board',
          measurements: 'Size: 4 x 3 ft.',
          route: 'cosmic-series-2',
          image: 'CosmicSeries2.png'
        },
        chasm: {
          title: 'Chasm',
          description: 'Mixed Media on Canvas',
          measurements: '36x28 in.',
          route: 'chasm',
          image: 'Chasm.png'
        },
        ethereal: {
          title: 'Ethereal',
          description: 'Mixed Media on Canvas',
          measurements: '30X20 in.',
          route: 'ethereal',
          image: 'Ethereal.png'
        },
        celestial: {
          title: 'Celestial',
          description: 'Mixed Media on Canvas',
          measurements: '36x28 in.',
          route: 'celestial',
          image: 'Celestial.png'
        },
        cosmic: {
          title: 'Cosmic',
          description: 'Mixed Media on Canvas',
          measurements: '36x28 in.',
          route: 'cosmic',
          image: 'Cosmic.png'
        },
        exuberance: {
          title: 'Exuberance',
          description: 'Mixed Media on Canvas',
          measurements: '28.5 x 36 in',
          route: 'exuberance',
          image: 'Exuberance.png'
        },
      },
      corrosion: {
        corrodedPassion: {
          title: 'Corroded Passion',
          description: 'Mixed Media on Canvas',
          measurements: '24x24 in.',
          route: 'corroded-passion',
          image: 'CorrodedPassion.png'
        },
        untitled: {
          title: 'Untitled',
          description: '24x24 in.',
          measurements: 'Mixed Media on Canvas',
          route: 'untitled',
          image: 'Untitled.png'
        },
        showMeYourTrueColors: {
          title: 'Show Me Your True Colors',
          description: '36x24 in.',
          measurements: 'Mixed Media on Canvas',
          route: 'show-me-your-true-colors',
          image: 'ShowMeYourTrueColors.png'
        },
        elope: {
          title: 'Elope',
          description: '48x36 in.',
          measurements: 'Mixed Media on Canvas',
          route: 'elope',
          image: 'Elope.png'
        },
        fade: {
          title: 'Fade',
          description: '36x24 in.',
          measurements: 'Mixed Media on Canvas',
          route: 'fade',
          image: 'Fade.png'
        },
        joyful: {
          title: 'Joyful',
          description: '55x45 in.',
          measurements: 'Mixed Media on Canvas',
          route: 'joyful',
          image: 'Joyful.png'
        },
      },
      flurry: {
        flurry: {
          title: 'Flurry',
          description: '36x36 in.',
          measurements: 'Acrylics on Canvas',
          route: 'flurry',
          image: 'Flurry.png'
        },
        success: {
          title: 'Success',
          description: 'Mixed Media on Canvas',
          measurements: '36x24 in.',
          route: 'success',
          image: 'Success.png'
        },
        dance: {
          title: 'Dance',
          description: 'Mixed Media on Canvas',
          measurements: '36x24 in.',
          route: 'dance',
          image: 'Dance.png'
        },
        wisp: {
          title: 'Wisp',
          description: 'Mixed Media on Canvas',
          measurements: '55x45 in.',
          route: 'wisp',
          image: 'Wisp.png'
        },
        madonna: {
          title: 'Madonna',
          description: 'Mixed Media on Canvas',
          measurements: '24x36 in.',
          route: 'madonna',
          image: 'Madonna.png'
        },
        delight: {
          title: 'Delight',
          description: 'Mixed Media on Canvas',
          measurements: '60x36 in.',
          route: 'delight',
          image: 'Delight.png'
        }
      },
      others: {
        unnamed: {
          title: '',
          description: '',
          measurements: '',
          route: 'unnamed',
          image: 'Unnamed.jpg'
        },
        storm: {
          title: 'Storm',
          description: 'Mixed media on canvas on board.',
          measurements: '36 x 48 in.',
          route: 'unnamed',
          image: 'Storm.jpg'
        },
        unnamed2: {
          title: '',
          description: 'Mixed media on canvas on plyboard.',
          measurements: '48 x 36 in.',
          route: 'unnamed-2',
          image: 'Unnamed2.jpg'
        },
        struggle: {
          title: 'Struggle',
          description: 'Mixed media on canvas on plyboard.',
          measurements: '48 x 36 in.',
          route: 'struggle',
          image: 'Struggle.jpg'
        },
        impasse: {
          title: 'Impasse',
          description: 'Acrylic on Canvas on Ply Board',
          measurements: '42x55 in.',
          route: 'impasse',
          image: 'Impasse.jpg'
        },
        phantasma: {
          title: 'Phantasma',
          description: 'Acrylic on Canvas',
          measurements: '36x60 in.',
          route: 'phantasma',
          image: 'Phantasma.jpg'
        },
        unnamed3: {
          title: '',
          description: 'Mixed Media on Canvas',
          measurements: '36x36 in.',
          route: 'unnamed-3',
          image: 'Unnamed3.jpg'
        },
        uncertainty: {
          title: 'Uncertainty',
          description: 'Mixed Media on Canvas',
          measurements: '36x23 in.',
          route: 'uncertainty',
          image: 'Uncertainty.jpg'
        },
        plot: {
          title: 'Plot',
          description: 'Mixed Media on Canvas',
          measurements: '48x36 in.',
          route: 'plot',
          image: 'Plot.jpg'
        },
        conflict: {
          title: 'Conflict',
          description: 'Mixed Media on Canvas',
          measurements: '54x40 in.',
          route: 'conflict',
          image: 'Conflict.jpg'
        },
        unnamed4: {
          title: '',
          description: 'Mixed Media on Canvas',
          measurements: '10x8 in.',
          route: 'unnamed-4',
          image: 'Unnamed4.jpg'
        },
        promise: {
          title: 'Promise',
          description: 'Mixed Media on Canvas',
          measurements: '24x24 in.',
          route: 'promise',
          image: 'Promise.jpg'
        },
        passage: {
          title: 'Passage',
          description: 'Acrylic on Canvas',
          measurements: '36x24 in.',
          route: 'passage',
          image: 'Passage.jpg'
        },
        foliage: {
          title: 'Foliage',
          description: 'Acrylic on Canvas',
          measurements: '16x12 in.',
          route: 'foliage',
          image: 'Foliage.jpg'
        },
        composition5: {
          title: 'Composition No. 5',
          description: 'Acrylic on Canvas',
          measurements: '24x24 in.',
          route: 'composition-no-5',
          image: 'Composition5.jpg'
        },
        endure: {
          title: 'Endure',
          description: 'Acrylic on Canvas Board',
          measurements: '55x42 in.',
          route: 'endure',
          image: 'Endure.jpg'
        },
        wander: {
          title: 'Wander',
          description: 'Acrylic on Canvas',
          measurements: '24x36 in.',
          route: 'wander',
          image: 'Wander.jpg'
        },
        unnamed5: {
          title: '',
          description: 'Acrylic on Canvas',
          measurements: '28.5x36 in.',
          route: 'unnamed-5',
          image: 'Unnamed5.jpg'
        },
        uplift: {
          title: 'Uplift',
          description: 'Mixed Media on Canvas on Ply Board',
          measurements: '42x55 in.',
          route: 'uplift',
          image: 'Uplift.jpg'
        },
        affection: {
          title: 'Affection',
          description: 'Acrylic on Canvas',
          measurements: '36x24 in.',
          route: 'affection',
          image: 'Affection.jpg'
        },
      },
      gathering: {
        betsy: {
          title: 'Betsy',
          description: 'Mixed Media on Wood Panel',
          measurements: '46x36 in.',
          route: 'betsy',
          image: 'Betsy.png'
        },
        abundance: {
          title: 'Abundance',
          description: 'Mixed Media on Canvas on Wood',
          measurements: '50x45 in.',
          route: 'abundance',
          image: 'Abundance.png'
        },
        urban: {
          title: 'Urban',
          description: 'Mixed Media on Canvas',
          measurements: '36x36 in.',
          route: 'urban',
          image: 'Urban.png'
        },
        favela: {
          title: 'Favela',
          description: 'Mixed Media on Canvas',
          measurements: '54x40 in.',
          route: 'favela',
          image: 'Favela.png'
        },
        theGathering: {
          title: 'The Gathering',
          description: 'Mixed Media on Canvas',
          measurements: '54x40 in.',
          route: 'the-gathering',
          image: 'TheGathering.png'
        },
        confetti: {
          title: 'Confetti',
          description: 'Mixed Media on Canvas',
          measurements: '24x24 in.',
          route: 'confetti',
          image: 'Confetti.png'
        },
        blockChain: {
          title: 'Block Chain',
          description: 'Mixed Media on Wood Panel',
          measurements: '55x42 in.',
          route: 'block-chain',
          image: 'BlockChain.png'
        },
      },
      horizon: {
        untitled: {
          title: 'Untitled.',
          description: 'Mixed media on canvas on plyboard.',
          measurements: '36 x 28.5 in. ',
          route: 'untitled',
          image: 'Untitled.png'
        },
        insight: {
          title: 'Insight.',
          description: 'Mixed mixed media on canvas.',
          measurements: '18 x 24 in. ',
          route: 'insight',
          image: 'Insight.png'
        },
        unnamed: {
          title: '',
          description: '',
          measurements: '',
          route: 'unnamed',
          image: 'Unnamed.png'
        },
        discovery: {
          title: '“Discovery”.',
          description: 'Mixed media on canvas.',
          measurements: '36 x 28.5 in. ',
          route: 'discovery',
          image: 'Discovery.png'
        },
        unnamed2: {
          title: '',
          description: 'Mixed media on canvas.',
          measurements: '23 x 15 in.',
          route: 'unnamed-2',
          image: 'Unnamed2.png'
        },
      },
      transmogrification: {
        mistake: {
          title: 'Mistake',
          description: 'Mixed Media on Canvas',
          measurements: '36x24 in.',
          route: 'mistake',
          image: 'Mistake.png'
        },
        lent: {
          title: 'Lent',
          description: 'Acrylic on Canvas',
          measurements: '60x36 in.',
          route: 'lent',
          image: 'Lent.png'
        },
        theTown: {
          title: 'The Town',
          description: 'Acrylic and Pastel on Canvas',
          measurements: '4x4 ft.',
          route: 'the-town',
          image: 'TheTown.png'
        },
        sherilsMelody: {
          title: 'Sheril’s Melody',
          description: 'Acrylic on Canvas',
          measurements: '24x24 in.',
          route: 'sherils-melody',
          image: 'SherilsMelody.png'
        },
        lyrics: {
          title: 'Lyrics',
          description: 'Acrylic on Canvas',
          measurements: '24x24 in.',
          route: 'lyrics',
          image: 'Lyrics.png'
        },
        dreamTree: {
          title: 'Dream Tree',
          description: 'Acrylic on Canvas',
          measurements: '36x24 in.',
          route: 'dream-tree',
          image: 'DreamTree.png'
        },
        madrid: {
          title: 'Madrid',
          description: 'Acrylic on Canvas',
          measurements: '24x24 in.',
          route: 'madrid',
          image: 'Madrid.png'
        },
        zest: {
          title: 'Zest',
          description: 'Acrylic on Canvas',
          measurements: '24x24 in.',
          route: 'zest',
          image: 'Zest.png'
        },
        symphony: {
          title: 'Symphony',
          description: 'Acrylic on Canvas',
          measurements: '36x24 in.',
          route: 'symphony',
          image: 'Symphony.png'
        },
        feminine: {
          title: 'Feminine',
          description: 'Acrylic on Canvas',
          measurements: '36x28 in.',
          route: 'feminine',
          image: 'Feminine.png'
        },
      },
      recentArtworks: {
        untitled2: {
          title: 'untitled',
          description: 'Mixed media on canvas.',
          measurements: '4 x 3 ft',
          route: 'untitled2',
          image: 'untitiled.png'
        },
        tranquille: {
          title: 'tranquille',
          description: 'Mixed media on canvas.',
          measurements: '3 x 4 ft',
          route: 'tranquille',
          image: 'tranquille.png'
        },
        rythm: {
          title: 'rythm',
          description: 'Mixed media on canvas.',
          measurements: '4 x 5 ft',
          route: 'rythm',
          image: 'rythm.png'
        },
        grace: {
          title: 'Grace',
          description: 'Mixed media on canvas on board.',
          measurements: '36 x 25 in',
          route: 'grace',
          image: 'grace.png'
        },
        imperfections: {
          title: 'imperfections',
          description: 'Acrylic on Canvas',
          measurements: '4 x 3 ft',
          route: 'imperfections',
          image: 'imperfections.png'
        },
        untitled: {
          title: 'untitled',
          description: 'Mixed media on canvas.',
          measurements: '3 x 2 ft',
          route: 'untitled',
          image: 'untitled.png'
        },
        untitled1: {
          title: 'Untitled',
          
          route: 'untitled1',
          image: 'blank.png'
        },
        dance: {
          title: 'dance',
          description: 'Mixed media on canvas.',
          measurements: '4 x 4 ft.',
          route: 'dance',
          image: 'dance.png'
        },
        luck: {
          title: 'luck',
          description: 'Mixed media on canvas.',
          measurements: '28.5 x 36 in.',
          route: 'luck',
          image: 'luck.png'
        },
        still: {
          title: 'still',
          description: 'Mixed media on canvas.',
          measurements: '36 x 28.5 in.',
          route: 'still',
          image: 'still.png'
        },
        fantasy: {
          title: 'fantasy',
          description: 'Mixed media on canvas.',
          measurements: '4 x 3 ft.',
          route: 'fantasy',
          image: 'fantasy.png'
        },
        relentless: {
          title: 'relentless',
          description: 'Mixed media on canvas.',
          measurements: '3 x 4 ft',
          route: 'relentless',
          image: 'relentless.png'
        },
        harmony: {
          title: 'harmony',
          description: 'Mixed media on canvas.',
          measurements: '24 x 18 in.',
          route: 'harmony',
          image: 'harmony.png'
        },
        flow: {
          title: 'flow',
          description: 'Mixed media on canvas on board.',
          measurements: '4 x 3 ft.',
          route: 'flow',
          image: 'flow.png'
        },
        sentiment3: {
          title: 'Sentiment Series: No. 1',
          description: 'Mixed media on canvas.',
          measurements: '36 x 28.5 in.',
          route: 'sentiment3',
          image: 'sentiment3.png'
        },
        sentiment2: {
          title: 'Sentiment Series: No. 2',
          description: 'Mixed media on canvas.',
          measurements: '36 x 28.5 in.',
          route: 'sentiment2',
          image: 'sentiment1.png'
        },
        sentiment: {
          title: 'Sentiment',
          description: 'Mixed media on canvas.',
          measurements: '36 x 28.5 in.',
          route: 'sentiment',
          image: 'sentiment2.png'
        },
       
      
        
      
        
      },
      artworks2024: {
        heureuse: {
          title: 'heureuse',
          description: 'Mixed media on canvas.',
          measurements: '4 x 5 ft.',
          route: 'heureuse',
          image: 'heureuse.png'
        },
        inception: {
          title: 'inception',
          description: 'Mixed media on canvas.',
          measurements: '18 x 14 in.',
          route: 'inception',
          image: 'inception.png'
        },
        blessings1: {
          title: 'blessings1',
          description: 'Mixed media on canvas.',
          measurements: '24 x 18 in.',
          route: 'blessings1',
          image: 'Blessings Series 1.jpeg'
        },
        blessings2: {
          title: 'blessings2',
          description: 'Mixed media on canvas.',
          measurements: '24 x 18 in.',
          route: 'blessings2',
          image: 'Blessings Series 2.jpeg'
        },
        blessings3: {
          title: 'blessings3',
          description: 'Mixed media on canvas.',
          measurements: '24 x 18 in.',
          route: 'blessings3',
          image: 'Blessings Series 3.jpeg'
        },
        candor: {
          title: 'candor',
          description: 'Mixed media on canvas.',
          measurements: '4 x 4 ft.',
          route: 'candor',
          image: 'Candor.jpeg'
        },
        chances: {
          title: 'chances',
          description: 'Mixed media on canvas.',
          measurements: '4 x 5 ft',
          route: 'chances',
          image: 'Chances.jpeg'
        },
        nostalgia: {
          title: 'nostalgia',
          description: 'Mixed media on canvas.',
          measurements: '4 x 3 ft',
          route: 'nostalgia',
          image: 'Nostalgia.jpeg'
        },
        paradox: {
          title: 'paradox',
          description: 'Mixed media on canvas.',
          measurements: '3 x 3 ft.',
          route: 'paradox',
          image: 'Paradox.jpeg'
        },
        perseverance: {
          title: 'perseverance',
          description: 'Mixed media on canvas.',
          measurements: '4 x 5 ft',
          route: 'perseverance',
          image: 'Perseverance.jpeg'
        },
        treasures: {
          title: 'treasures',
          description: 'Mixed media on canvas.',
          measurements: '4 x 3 ft',
          route: 'treasures',
          image: 'Treasures.jpeg'
        },
        untitled1: {
          title: 'untitled1',
          description: 'Mixed media on canvas.',
          measurements: '20 x 16 in.',
          route: 'untitled1',
          image: 'Untitled no 1.jpeg'
        },
        untitled2: {
          title: 'untitled2',
          description: 'Mixed media on canvas.',
          measurements: '20 x 16 in.',
          route: 'untitled2',
          image: 'Untitled no2.jpeg'
        },
        untitled: {
          title: 'untitled',
          description: 'Mixed media on canvas.',
          measurements: '3 x 4 ft.',
          route: 'untitled',
          image: 'Untitled.jpeg'
        },
        

        
        
      },
      
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
